import React from 'react';
import { UitkExpandoList, UitkExpandoListItem } from 'uitk-react-expando';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
import Sidebar from '../sidebar/sidebar';
import useLocale from '../../hooks/useLocale';
import useSideBarVisibility from '../../hooks/useSideBarVisiblity';
const MobileSidebarBody = () => React.createElement(React.Fragment, null, React.createElement(UitkSpacingHr, null), React.createElement(Sidebar, null));
const MobileSidebarTitle = ({
  label
}) => React.createElement("div", {
  className: "sidebar-mobile-title"
}, React.createElement(UitkText, {
  weight: "medium"
}, label));
const MobileSidebar = ({
  hideSideBar = false
}) => {
  const {
    formatContent
  } = useLocale();
  const {
    expandMobileSideBarMenu,
    toggleMobileSideBarMenu
  } = useSideBarVisibility();
  return React.createElement("div", {
    className: hideSideBar ? 'sidebar-mobile-hidden' : ''
  }, React.createElement(UitkExpandoList, null, React.createElement(UitkExpandoListItem, {
    onToggle: toggleMobileSideBarMenu,
    expandoDescription: React.createElement(MobileSidebarBody, null),
    triggerPosition: "trailing",
    isVisible: expandMobileSideBarMenu,
    expandoTitle: React.createElement(MobileSidebarTitle, {
      label: formatContent('menu', 'Menu')
    }),
    isIndented: false,
    className: "sidebar-expando-trigger"
  })));
};
export default MobileSidebar;