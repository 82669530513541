import React, { useState } from 'react';
import { UitkSecondaryButton } from 'uitk-react-button';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkMenu, UitkMenuContainer, UitkMenuTrigger } from 'uitk-react-menu';
import { PositionType } from 'uitk-react-menu';
import { AuthenticatedUserMenuContent } from './../authenticated-user-menu/authenticated-user-menu-content';
export const AuthenticatedUserMenuDesktop = ({
  menu
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return React.createElement(UitkMenu, {
    isOpen: isOpen,
    onTriggerClick: () => setIsOpen(prevState => !prevState)
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkSecondaryButton, null, React.createElement(UitkIcon, {
    name: menu.icon.token,
    size: UitkIconSize.XLARGE
  }), menu.title)), React.createElement(UitkMenuContainer, {
    position: PositionType.RIGHT,
    width: 375,
    className: "authenticated-menu-container"
  }, React.createElement(AuthenticatedUserMenuContent, {
    menu: menu
  })));
};