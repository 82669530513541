import React from 'react';
import useNavigationParams from 'hooks/useNavigationParams';
import SidebarLink from '../sidebar-link/sidebar-link';
import { isSidebarActiveLink } from 'utils/sidebar-navigation';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
const SidebarExpandoLinks = ({
  navigationLinks
}) => {
  const {
    currentPath
  } = useNavigationParams();
  return React.createElement(UitkSpacing, {
    padding: {
      inlinestart: 'two'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, navigationLinks.map(navigationLink => React.createElement(SidebarLink, {
    key: navigationLink.link.text,
    navigationLink: navigationLink,
    isActive: isSidebarActiveLink(navigationLink, currentPath)
  }))));
};
export default SidebarExpandoLinks;