import React from 'react';
import { useHistory } from 'react-router-dom';
import { UitkLink } from 'uitk-react-link';
import SidebarButton from '../sidebar-button/sidebar-button';
import { getPathName } from '../../../utils/common';
import useSideBarVisibility from '../../../hooks/useSideBarVisiblity';
const SidebarLink = ({
  navigationLink,
  isActive
}) => {
  const {
    push
  } = useHistory();
  const {
    setExpandMobileSideBarMenu
  } = useSideBarVisibility();
  const onLinkClickHandler = e => {
    e.preventDefault();
    setExpandMobileSideBarMenu(false);
    const url = navigationLink.link.action.resource.value;
    const target = navigationLink.link.action.target;
    if (target === 'EXTERNAL') {
      window.open(url, '_blank');
    } else {
      push(getPathName(url));
    }
  };
  return React.createElement(React.Fragment, null, React.createElement(UitkLink, null, React.createElement("a", {
    href: navigationLink.link.action.resource.value,
    onClick: onLinkClickHandler
  }, React.createElement(SidebarButton, {
    label: navigationLink.link.text,
    isActive: isActive,
    icon: navigationLink.link.icon,
    badge: navigationLink.badge ? {
      text: navigationLink.badge.text,
      size: navigationLink.badge.size,
      theme: navigationLink.badge.theme
    } : null
  }))));
};
export default SidebarLink;