import React from 'react';
import { UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
const spacingPaddingProps = {
  medium: {
    block: 'sixteen'
  },
  small: {
    block: 'sixteen'
  }
};
export const RelationsSection = ({
  formatContent
}) => {
  return React.createElement(UitkLayoutFlexItem, {
    className: "relations-container home-spacing"
  }, React.createElement("section", {
    id: "relations-section"
  }, React.createElement(UitkSpacing, {
    padding: spacingPaddingProps
  }, React.createElement(UitkLayoutGrid, {
    columns: {
      medium: ['1fr']
    },
    space: "twelve",
    className: "marketing-page-width"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockstart: 'three'
    }
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(UitkText, {
    align: "center",
    size: 700
  }, formatContent('brandsSection.Relation')))), React.createElement(UitkLayoutGrid, {
    space: {
      column: 'four',
      row: 'six'
    },
    columns: {
      medium: 4,
      small: 1
    },
    className: "cards"
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(UitkSpacing, {
    padding: 'twelve'
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: "center",
    size: 600
  }, formatContent('brandsSection.Relation.numberOfTravelerReviews')), React.createElement(UitkText, {
    align: "center",
    size: 300,
    spacing: "two"
  }, formatContent('brandsSection.Relation.travelerReviews'))))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkSpacing, {
    padding: 'twelve'
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: "center",
    size: 600
  }, formatContent('brandsSection.Relation.numberOfPropertyImages')), React.createElement(UitkText, {
    align: "center",
    size: 300,
    spacing: "two"
  }, formatContent('brandsSection.Relation.propertyImages'))))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkSpacing, {
    padding: 'twelve'
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: "center",
    size: 600
  }, formatContent('brandsSection.Relation.numberOfintelligentGeographyMappings')), React.createElement(UitkText, {
    align: "center",
    size: 300,
    spacing: "two"
  }, formatContent('brandsSection.Relation.intelligentGeographyMappings'))))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkSpacing, {
    padding: 'twelve'
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: "center",
    size: 600
  }, formatContent('brandsSection.Relation.numberOfLanguagesOffered')), React.createElement(UitkText, {
    align: "center",
    size: 300,
    spacing: "two"
  }, formatContent('brandsSection.Relation.languagesOffered'))))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkSpacing, {
    padding: 'twelve'
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: "center",
    size: 600
  }, formatContent('brandsSection.Relation.numberOfAIPredictions')), React.createElement(UitkText, {
    align: "center",
    size: 300,
    spacing: "two"
  }, formatContent('brandsSection.Relation.AIPredictions'))))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkSpacing, {
    padding: 'twelve'
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: "center",
    size: 600
  }, formatContent('brandsSection.Relation.numberOfVirtualConversations')), React.createElement(UitkText, {
    align: "center",
    size: 300,
    spacing: "two"
  }, formatContent('brandsSection.Relation.virtualConversations'))))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkSpacing, {
    padding: 'twelve'
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: "center",
    size: 600
  }, formatContent('brandsSection.Relation.numberOfDataPointsTracked')), React.createElement(UitkText, {
    align: "center",
    size: 300,
    spacing: "two"
  }, formatContent('brandsSection.Relation.dataPointsTracked'))))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkSpacing, {
    padding: 'twelve'
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: "center",
    size: 600
  }, formatContent('brandsSection.Relation.numberOfUniqueActivities')), React.createElement(UitkText, {
    align: "center",
    size: 300,
    spacing: "two"
  }, formatContent('brandsSection.Relation.uniqueActivities'))))))))));
};
RelationsSection.displayName = 'RelationsSection';