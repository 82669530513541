import debounce from 'lodash.debounce';
export class ElasticSearchAdapter {
  constructor(config) {
    this.config = config;
    this.categories = {};
    this.results = [];
  }
  onItemSelection(data) {
    return Promise.resolve(data);
  }
  getResults() {
    return this.results;
  }
  async fetchResponse() {
    return Promise.resolve({
      sr: []
    });
  }
  adaptResponseToTypeaheadResults() {
    return new Array();
  }
  async fetchResults(term) {
    this.results = [];
    if (term) {
      await new Promise(resolve => {
        fetchSearchResults(term, this.config, results => {
          this.results = results;
          resolve(this.results);
        });
      });
    }
    return this.results;
  }
  async fetchResultsCategorized() {
    throw new Error('Method not implemented.');
  }
  fetchRecentSearchResults() {
    throw new Error('Method not implemented.');
  }
  fetchRecentSearchResultsCategorized() {
    throw new Error('Method not implemented.');
  }
  isHistoryDetailEnabled() {
    return false;
  }
  fetchAlternativeResults() {
    return Promise.resolve([]);
  }
  logResponse(results) {
    return Promise.resolve(results);
  }
  typeaheadDialogOpened() {}
  logSuggestionShown() {}
  logTypeaheadDismiss() {}
  setTypeaheadCategories() {}
  getTypeaheadCategories() {
    return this.categories;
  }
}
async function search(documents) {
  return documents === null || documents === void 0 ? void 0 : documents.map(matchingDocument => {
    return {
      name: `<div class="search-result-location">${matchingDocument.parentTitles}</div>`,
      title: matchingDocument.title,
      value: matchingDocument.path,
      icon: null,
      subtext: `<span class="search-result-title">${matchingDocument.title}</span>: ${matchingDocument.intro || ''}`
    };
  });
}
const fetchSearchResults = debounce(async (term, config, updateResultsCb) => {
  const {
    data
  } = await config.refetchResults({
    searchTerm: term,
    context: config.context,
    hubId: config.hubId
  });
  const results = await search(data.documentation.search.searchResults);
  updateResultsCb(results);
}, 1000);