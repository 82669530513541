import React, { memo, useEffect } from 'react';
import { useBexApi } from '@shared-ui/bex-api-context';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import useNavigationParams from '../../hooks/useNavigationParams';
import useGraphqlErrors from '../../hooks/useGraphqlErrors';
import PageHeaderMobile from './mobile/page-header-mobile';
import PageHeaderDesktop from './desktop/page-header-desktop';
import { useHeaderQuery } from '../../graphql/__generated__/types';
const PageHeader = () => {
  const {
    context
  } = useBexApi();
  const {
    hubId
  } = useNavigationParams();
  const {
    setHasError
  } = useGraphqlErrors();
  const {
    data,
    error,
    loading
  } = useHeaderQuery({
    variables: {
      context,
      hubId
    }
  });
  useEffect(() => {
    if (error) setHasError(true);
  }, [error]);
  if (!data || loading) {
    return null;
  }
  const {
    header
  } = data.documentation;
  return React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(PageHeaderMobile, {
    headerData: header
  })), React.createElement(ViewLarge, null, React.createElement(PageHeaderDesktop, {
    headerData: header
  })));
};
export default memo(PageHeader);