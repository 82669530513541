import React from 'react';
import useNavigationParams from 'hooks/useNavigationParams';
import SidebarLink from '../sidebar-link/sidebar-link';
import { isActiveExpando, isSidebarActiveLink } from 'utils/sidebar-navigation';
import SidebarExpando from '../sidebar-expando/sidebar-expando';
const SidebarSectionElements = ({
  navigationElements
}) => {
  const {
    currentPath
  } = useNavigationParams();
  return React.createElement(React.Fragment, null, navigationElements.map(element => {
    if (element.__typename === 'DocumentationNavigationLinkElement') {
      return React.createElement(SidebarLink, {
        key: element.link.text,
        navigationLink: element,
        isActive: isSidebarActiveLink(element, currentPath)
      });
    } else if (element.__typename === 'DocumentationNavigationExpandoElement') {
      return React.createElement(SidebarExpando, {
        key: element.label,
        expando: element,
        isActive: isActiveExpando(element.navigationElements, currentPath)
      });
    }
  }));
};
export default SidebarSectionElements;