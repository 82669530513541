import React from 'react';
import { UitkBadgeStandard } from 'uitk-react-badge';
export const SidebarBadge = ({
  text,
  size,
  theme
}) => {
  return React.createElement(UitkBadgeStandard, {
    text: text,
    size: size,
    theme: theme
  });
};