import React, { useState } from 'react';
import { UitkIcon } from 'uitk-react-icons';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkMenuList } from 'uitk-react-menu';
import { UitkMenu, UitkMenuContainer, UitkMenuTrigger } from 'uitk-react-menu';
const LanguageSelectorDesktop = props => {
  const {
    languageMenuItems,
    currentLanguage,
    primaryIconName,
    secondaryIconName
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  return React.createElement(UitkMenu, {
    isOpen: isOpen,
    onTriggerClick: () => setIsOpen(prevState => !prevState)
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkTertiaryButton, {
    name: "language-selection",
    "data-testid": "language-selection-button"
  }, React.createElement(UitkIcon, {
    name: primaryIconName
  }), currentLanguage.title, React.createElement(UitkIcon, {
    name: secondaryIconName
  }))), React.createElement(UitkMenuContainer, {
    className: "language-selection-menu"
  }, React.createElement(UitkMenuList, {
    items: languageMenuItems
  })));
};
export default LanguageSelectorDesktop;