import React, { useEffect } from 'react';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { UitkSpacing } from 'uitk-react-spacing';
import { ViewExtraLarge, Viewport } from '@shared-ui/viewport-context';
import { ErrorBoundary } from 'bernie-view';
import { useBexApi } from '@shared-ui/bex-api-context';
import { GenericErrorMessage } from '../common/generic-error-message';
import useNavigationParams from '../../hooks/useNavigationParams';
import EgMarkdownRenderer from '@eg-markdown-renderer/core';
import TableOfContent from 'components/documentation-page/table-of-content';
import DocumentErrorView from './document-error-view';
import LoadingSkeleton from '../documentation-page/loading-skeleton';
import { useDocumentQuery } from '../../graphql/__generated__/types';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import DocumentationNavigator from '../documentation-navigator/documentation-navigator';
import RelatedSection from '../related-section/related-section';
import Footer from '../footer';
import MessageBanner from '../common/message-banner';
import useAnalytics from '../../hooks/analytics/useAnalytics';
import ContentFeedback from '../content-feedback/content-feedback';
import CustomerSurvey from '../customer-survey/customer-survey';
import { useMarkdownRendererOptions } from 'hooks/useMarkdownRendererOptions';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import useMessageBanner from 'hooks/useMessageBanner';
import SurveyTrigger from '../survey-trigger/survey-trigger';
import useSideBarVisibility from '../../hooks/useSideBarVisiblity';
import HeaderTitle from '../header-title';
import DocumentationTemplateRenderer from '@documentation-template-renderer/core';
const DocumentView = () => {
  var _a, _b, _c;
  const {
    hubId,
    hash,
    documentId
  } = useNavigationParams();
  const {
    context
  } = useBexApi();
  const {
    setMessageBanner
  } = useMessageBanner();
  const {
    data,
    loading,
    error
  } = useDocumentQuery({
    variables: {
      context,
      hubId,
      documentId
    }
  });
  const analytics = useAnalytics();
  const {
    componentsOptions,
    contentVersion
  } = useMarkdownRendererOptions(hubId);
  const {
    hideSideBar
  } = useSideBarVisibility();
  useEffect(() => {
    var _a;
    if (!hash) (_a = document === null || document === void 0 ? void 0 : document.getElementById('document')) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
  }, [documentId]);
  const documentTypeName = (_b = (_a = data === null || data === void 0 ? void 0 : data.documentation) === null || _a === void 0 ? void 0 : _a.document) === null || _b === void 0 ? void 0 : _b.__typename;
  React.useEffect(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!loading && ((_b = (_a = data === null || data === void 0 ? void 0 : data.documentation) === null || _a === void 0 ? void 0 : _a.document) === null || _b === void 0 ? void 0 : _b.notifications) !== null) {
      setMessageBanner({
        message: (_e = (_d = (_c = data.documentation) === null || _c === void 0 ? void 0 : _c.document) === null || _d === void 0 ? void 0 : _d.notifications[0]) === null || _e === void 0 ? void 0 : _e.message,
        iconName: (_h = (_g = (_f = data.documentation) === null || _f === void 0 ? void 0 : _f.document) === null || _g === void 0 ? void 0 : _g.notifications[0]) === null || _h === void 0 ? void 0 : _h.icon.token,
        messageStyle: {
          marginLeft: '1rem'
        }
      });
    }
  }, [(_c = data === null || data === void 0 ? void 0 : data.documentation) === null || _c === void 0 ? void 0 : _c.document]);
  useEffect(() => {
    if (!loading && !error && documentTypeName === 'DocumentationDocumentSuccessResponse') {
      analytics.trackPageView();
    }
  }, [documentId, loading, error]);
  if (error) {
    return React.createElement(GenericErrorMessage, null);
  }
  if (loading || !data) {
    return React.createElement(UitkLayoutGrid, {
      columns: 10
    }, React.createElement(UitkLayoutGridItem, {
      ...documentGridColumnProps
    }, React.createElement(UitkSpacing, {
      ...documentContainerSpacingProps
    }, React.createElement("div", null, React.createElement(LoadingSkeleton, null)))));
  }
  if (documentTypeName === 'DocumentationDocumentErrorResponse') {
    return React.createElement(DocumentErrorView, {
      errorResponse: data.documentation.document
    });
  }
  const {
    content,
    banner,
    title,
    intro,
    navigationTrail,
    type,
    format
  } = data.documentation.document;
  const gridProps = {
    ...documentGridColumnProps,
    colEnd: {
      extra_large: type === 'reference' ? 10 : 8,
      small: 12
    },
    className: type === 'reference' ? '' : 'large-max-width'
  };
  if (format === 'json') {
    return React.createElement(React.Fragment, null, React.createElement(MessageBanner, {
      message: banner === null || banner === void 0 ? void 0 : banner.message,
      iconName: banner === null || banner === void 0 ? void 0 : banner.icon.token,
      spacing: {
        margin: {
          blockend: 'four'
        }
      }
    }), React.createElement(ErrorBoundary, {
      label: "document.body",
      key: documentId,
      fallback: React.createElement(GenericErrorMessage, null)
    }, React.createElement("div", {
      className: "md-renderer-wrapper"
    }, React.createElement(DocumentationTemplateRenderer, {
      jsonBody: content
    }))));
  }
  return React.createElement(UitkLayoutGrid, {
    columns: 10,
    style: {
      flexGrow: 1
    }
  }, React.createElement(UitkLayoutGridItem, {
    ...gridProps
  }, React.createElement(UitkSpacing, {
    ...documentContainerSpacingProps
  }, React.createElement("div", null, React.createElement(Experiment, {
    name: "DevHub_Breadcrumbs"
  }, React.createElement(ExperimentControl, null, React.createElement(React.Fragment, null)), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(Breadcrumbs, {
    crumbs: navigationTrail
  }))), React.createElement(MessageBanner, {
    message: banner === null || banner === void 0 ? void 0 : banner.message,
    iconName: banner === null || banner === void 0 ? void 0 : banner.icon.token,
    spacing: {
      margin: {
        blockend: 'four'
      }
    }
  }), React.createElement(ErrorBoundary, {
    label: "document.body",
    key: documentId,
    fallback: React.createElement(GenericErrorMessage, null)
  }, React.createElement("div", {
    className: "md-renderer-wrapper"
  }, React.createElement(EgMarkdownRenderer, {
    mdBody: content,
    componentsOptions: componentsOptions
  }))), React.createElement(UitkSpacing, {
    margin: {
      block: 'twelve'
    }
  }, React.createElement("div", null, React.createElement(RelatedSection, null))), React.createElement(UitkSpacing, {
    margin: {
      block: 'twelve'
    }
  }, React.createElement("div", null, React.createElement(ContentFeedback, null))), !hideSideBar ? React.createElement(UitkSpacing, {
    ...navigatorSpacingProps
  }, React.createElement("div", null, React.createElement(DocumentationNavigator, null))) : null))), type !== 'reference' ? React.createElement(Viewport, null, React.createElement(ViewExtraLarge, null, React.createElement(UitkLayoutGridItem, {
    colSpan: 2,
    alignSelf: "start"
  }, React.createElement(UitkLayoutPosition, {
    type: "sticky",
    position: {
      top: 'six'
    }
  }, React.createElement(TableOfContent, {
    contentVersion: contentVersion
  }))))) : React.createElement(React.Fragment, null), React.createElement(UitkLayoutGridItem, {
    ...footerGridColumnProps,
    style: {
      alignSelf: 'end'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(Footer, null))))), React.createElement(Experiment, {
    name: "DevHub_Feedback_Form"
  }, React.createElement(ExperimentControl, null, !hubId.includes('mock') ? React.createElement(UitkLayoutPosition, {
    type: "absolute",
    position: {
      bottom: 'twelve',
      right: 'twelve'
    }
  }, React.createElement(SurveyTrigger, null)) : null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, !hubId.includes('mock') && hubId !== 'platform-docs' ? React.createElement(CustomerSurvey, null) : null)), React.createElement(HeaderTitle, {
    documentTitle: title,
    content: intro
  }));
};
const documentGridColumnProps = {
  colSpan: {
    small: 'all'
  },
  colStart: {
    extra_large: 2,
    small: 1
  },
  colEnd: {
    extra_large: 8,
    small: 12
  }
};
const footerGridColumnProps = {
  ...documentGridColumnProps,
  colSpan: 'all',
  colStart: 2,
  rowStart: 0,
  rowEnd: 0
};
const documentContainerSpacingProps = {
  margin: {
    large: {
      block: 'six'
    }
  },
  padding: {
    small: {
      inline: 'four',
      block: 'three'
    },
    large: {
      block: 'three'
    },
    extra_large: {
      inline: 'unset'
    }
  }
};
const navigatorSpacingProps = {
  padding: {
    blockend: 'six'
  }
};
export default DocumentView;