import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { useBexApi } from '@shared-ui/bex-api-context';
import useNavigationParams from '../../hooks/useNavigationParams';
import { useRelationshipsQuery } from '../../graphql/__generated__/types';
import NavigatorLink from './navigator-link';
const DocumentationNavigator = () => {
  const {
    hubId,
    documentId
  } = useNavigationParams();
  const {
    context
  } = useBexApi();
  const {
    data,
    loading,
    error
  } = useRelationshipsQuery({
    variables: {
      context,
      hubId,
      documentId
    }
  });
  if (error || loading || !data) {
    return null;
  }
  const relationships = data.documentation.relationships;
  const {
    previous,
    next
  } = relationships;
  return React.createElement(UitkSpacing, {
    margin: {
      blockend: 'six',
      blockstart: 'two'
    },
    padding: {
      blockstart: 'six'
    },
    border: ['blockstart'],
    className: "width-full"
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: "space-between",
    space: "four"
  }, React.createElement("div", {
    "data-stid": "previous"
  }, React.createElement(UitkLayoutFlexItem, {
    maxWidth: "half_width",
    className: "navigation-item"
  }, previous && React.createElement(NavigatorLink, {
    link: previous,
    alignment: "left"
  }))), React.createElement("div", {
    "data-stid": "next"
  }, React.createElement(UitkLayoutFlexItem, {
    maxWidth: "half_width",
    className: "navigation-item next"
  }, next && React.createElement(NavigatorLink, {
    link: next,
    alignment: "right"
  })))));
};
export default DocumentationNavigator;