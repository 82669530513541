import React from 'react';
const HomePageMobileLayout = ({
  header,
  sidebar,
  document
}) => React.createElement("div", {
  id: "home-page-layout-mobile"
}, React.createElement("header", {
  id: "page-header-mobile"
}, header), React.createElement("main", {
  id: "page-body-mobile"
}, React.createElement("section", {
  id: "sidebar-mobile"
}, sidebar), React.createElement("section", {
  id: "document-mobile"
}, document)));
export default HomePageMobileLayout;