import React from 'react';
import { UitkBreadcrumbs } from 'uitk-react-breadcrumbs';
import { Link } from 'react-router-dom';
import { getPathName } from '../../utils/common';
import { UitkLink } from 'uitk-react-link';
import { UitkText } from 'uitk-react-text';
export const Breadcrumbs = ({
  crumbs
}) => {
  if (!crumbs || crumbs.length === 0) return null;
  const crumbsLinks = crumbs.map(crumb => crumb.__typename === 'EGDSStandardLink' ? React.createElement(UitkLink, {
    inline: true,
    size: "small"
  }, React.createElement(Link, {
    to: getPathName(crumb.action.resource.value)
  }, crumb.text)) : React.createElement(UitkText, {
    size: 200
  }, crumb.text));
  return React.createElement(UitkBreadcrumbs, {
    hasDynamicInlineCrumbs: true,
    crumbs: crumbsLinks
  });
};