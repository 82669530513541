import React, { useEffect, useState } from 'react';
import { UitkExpandoListItem } from 'uitk-react-expando';
import SidebarExpandoTitle from './sidebar-expando-title';
import SidebarExpandoLinks from './sidebar-expando-links';
import useNavigationParams from '../../../hooks/useNavigationParams';
import { isActiveExpando } from '../../../utils/sidebar-navigation';
const SidebarExpando = ({
  expando,
  isActive
}) => {
  const [isExpanded, setIsExpanded] = useState(isActive);
  const {
    currentPath
  } = useNavigationParams();
  useEffect(() => {
    if (isActiveExpando(expando.navigationElements, currentPath)) {
      setIsExpanded(true);
    }
  }, [currentPath]);
  const ExpandoTitle = React.createElement(SidebarExpandoTitle, {
    label: expando.label,
    isHighlighted: isActive,
    onClick: () => setIsExpanded(prevState => !prevState),
    badge: expando.badge ? {
      text: expando.badge.text,
      size: expando.badge.size,
      theme: expando.badge.theme
    } : null
  });
  return React.createElement(UitkExpandoListItem, {
    expandoTitle: ExpandoTitle,
    expandoDescription: React.createElement(SidebarExpandoLinks, {
      navigationLinks: expando.navigationElements
    }),
    isVisible: isExpanded,
    onToggle: () => setIsExpanded(prevState => !prevState),
    triggerPosition: "trailing"
  });
};
export default SidebarExpando;