import React from 'react';
import { BaseDialog } from './base-dialog';
import useLocale from '../../hooks/useLocale';
export const PartnerSwitchDialog = () => {
  const {
    formatContent
  } = useLocale();
  return React.createElement(BaseDialog, {
    dialogId: "partner-switch",
    dialogLabel: formatContent('devhub.dialog.switching-accounts.label'),
    headerText: formatContent('devhub.dialog.switching-accounts.header'),
    loading: true,
    textCentered: true,
    isDismissable: false
  });
};