import React from 'react';
import { UitkSpacing } from 'uitk-react-spacing';
import { Search } from 'components/page-header/search';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import HeaderLogo from 'components/page-header/header-logo';
import LanguageSelector from 'components/page-header/language-selector';
import HeaderNavbar from 'components/page-header/header-navbar';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { ElasticSearch } from '../elastic-search';
import { useDarkMode } from 'hooks/useDarkMode';
import { DarkModeToggle } from 'components/common/dark-mode-toggle';
import { AuthenticationMobile } from './authentication-mobile';
const PageHeaderMobile = ({
  headerData
}) => {
  const {
    logo,
    searchBox,
    languageMenu,
    primaryNavigation,
    authentication,
    secondaryNavigation,
    hubName
  } = headerData;
  const isDarkMode = useDarkMode();
  return React.createElement(UitkSpacing, {
    padding: {
      inline: 'two',
      blockstart: 'two'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    justifyContent: "space-between",
    className: "height-full"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    alignItems: "center"
  }, React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement("div", null, React.createElement(HeaderLogo, {
    logo: logo,
    hubName: hubName
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement("div", {
    "data-testid": "searchbox"
  }, React.createElement(Experiment, {
    name: "DevHub_Search_Behind_Login"
  }, React.createElement(ExperimentControl, null, React.createElement(Search, {
    searchInputProps: searchBox
  })), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(ElasticSearch, {
    searchInputProps: searchBox
  }))))), typeof window !== 'undefined' && isDarkMode && React.createElement(UitkLayoutFlexItem, null, React.createElement(DarkModeToggle, null)), languageMenu && React.createElement(UitkLayoutFlexItem, null, React.createElement(LanguageSelector, {
    menu: languageMenu
  })), React.createElement(Experiment, {
    name: "DevHub_AuthN_AuthZ"
  }, React.createElement(ExperimentControl, null, React.createElement(React.Fragment, null)), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(AuthenticationMobile, {
    authentication: authentication
  })))))), React.createElement(UitkLayoutFlexItem, null, React.createElement(HeaderNavbar, {
    primaryNavigation: primaryNavigation,
    secondaryNavigation: secondaryNavigation
  }))));
};
export default PageHeaderMobile;