import React from 'react';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { useBexApi } from '@shared-ui/bex-api-context';
import { useCustomerSurveyQuery } from '../../graphql/__generated__/types';
import useNavigationParams from '../../hooks/useNavigationParams';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import useLocale from './../../hooks/useLocale';
const CustomerSurvey = () => {
  var _a, _b;
  const {
    context
  } = useBexApi();
  const {
    hubId
  } = useNavigationParams();
  const {
    data,
    loading,
    error
  } = useCustomerSurveyQuery({
    variables: {
      hubId,
      context
    }
  });
  const [isTexthidden, setTextToogle] = React.useState(true);
  const {
    formatContent
  } = useLocale();
  const openPopup = popupUrl => e => {
    const height = 650;
    const width = 600;
    const top = window.outerHeight / 2 + window.screenY - height / 2;
    const left = window.outerWidth / 2 + window.screenX - width / 2;
    const queryParams = [{
      key: 'url',
      value: window.location.href
    }, {
      key: 'duaid',
      value: context.identity.duaid
    }];
    const queryString = queryParams.filter(({
      value
    }) => value).map(({
      key,
      value
    }) => `${key}=${encodeURI(value)}`).join('&');
    const surveyUrl = `${popupUrl}/?${queryString}`;
    const popupParams = [`toolbar=no`, `scrollbars=no`, `location=no`, `statusbar=no`, `menubar=no`, `resizable=no`, `width=${width}px`, `height=${height}px`, `left=${left}`, `top=${top}`].join(',');
    e.stopPropagation();
    const popup = window.open(surveyUrl, '', popupParams);
    popup === null || popup === void 0 ? void 0 : popup.focus();
  };
  const openPopupOnKeyDown = popupUrl => e => {
    if (e.key === 'Enter') {
      openPopup(popupUrl)(e);
    }
  };
  if (loading || error || !data) return null;
  const customerSurveyButton = (_b = (_a = data === null || data === void 0 ? void 0 : data.documentation) === null || _a === void 0 ? void 0 : _a.customerSurvey) === null || _b === void 0 ? void 0 : _b.button;
  if (customerSurveyButton === null) return null;
  const surveyUrl = customerSurveyButton.action.resource.value;
  return React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(UitkLayoutPosition, {
    type: "absolute",
    position: {
      bottom: 'two',
      right: 'six'
    }
  }, React.createElement(UitkPrimaryButton, {
    onClick: openPopup(surveyUrl),
    onKeyDown: openPopupOnKeyDown(surveyUrl),
    onMouseOver: () => setTextToogle(false),
    onMouseLeave: () => setTextToogle(true),
    "aria-label": formatContent('survey.aria.label')
  }, React.createElement(UitkIcon, {
    name: customerSurveyButton.icon.token
  }), !isTexthidden && customerSurveyButton.primary))), React.createElement(ViewSmall, null, React.createElement(UitkLayoutPosition, {
    type: "absolute",
    position: {
      bottom: 'three',
      right: 'three'
    }
  }, React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.SMALL,
    onClick: openPopup(surveyUrl),
    onKeyDown: openPopupOnKeyDown(surveyUrl),
    "aria-label": formatContent('survey.aria.label')
  }, React.createElement(UitkIcon, {
    name: customerSurveyButton.icon.token,
    size: UitkIconSize.SMALL
  }), !isTexthidden && customerSurveyButton.primary))));
};
export default CustomerSurvey;