import React, { useState } from 'react';
import { UitkMenu, UitkMenuContainer, UitkMenuTrigger } from 'uitk-react-menu';
import { PositionType, UitkMenuIconTrigger } from 'uitk-react-menu';
import { AuthenticatedUserMenuContent } from './../authenticated-user-menu/authenticated-user-menu-content';
export const AuthenticatedUserMenuMobile = ({
  menu
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return React.createElement(UitkMenu, {
    isOpen: isOpen,
    onTriggerClick: () => setIsOpen(prevState => !prevState)
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkMenuIconTrigger, {
    icon: menu.icon.token
  })), React.createElement(UitkMenuContainer, {
    position: PositionType.RIGHT,
    width: 375,
    className: "authenticated-menu-container"
  }, React.createElement(AuthenticatedUserMenuContent, {
    menu: menu
  })));
};