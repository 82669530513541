import React from 'react';
import { UitkText } from 'uitk-react-text';
const ProductPlaceHolder = ({
  label
}) => {
  return React.createElement("div", {
    className: "product-placeholder"
  }, React.createElement(UitkText, {
    weight: "medium",
    theme: "inverse",
    size: 400
  }, label));
};
export default ProductPlaceHolder;