import React from 'react';
import { UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { BatmanIcon } from 'components/common/easter-eggs/batman-icon';
import { useDarkMode } from 'hooks/useDarkMode';
export const DarkModeToggle = () => {
  const isDarkMode = useDarkMode();
  return React.createElement(UitkTertiaryButton, {
    size: UitkButtonSize.MEDIUM,
    href: `${window.location.origin}${window.location.pathname}?darkMode=${!isDarkMode}`
  }, React.createElement(BatmanIcon, null));
};