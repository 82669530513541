import React, { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { UitkCard } from 'uitk-react-cards';
import { UitkHeading } from 'uitk-react-text';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLink } from 'uitk-react-link';
import useLocale from '../../hooks/useLocale';
import Storage from '../../utils/storage';
import { UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
// Survey trigger will appear again after 45 days are passed
const COOL_OFF_TIME = 45 * 24 * 60 * 60 * 1000;
const DEFAULT_TIME_BEFORE_DISPLAYING = 3 * 1000;
const SurveyTrigger = ({
  timeBeforeDisplaying = DEFAULT_TIME_BEFORE_DISPLAYING
}) => {
  const {
    formatContent
  } = useLocale();
  const [showTrigger, setShowTrigger] = useState(hasSurveyBeenDisplayed() && !hasSurveyBeenDismissed());
  const nodeRef = useRef(null);
  useEffect(() => {
    if (hasSurveyBeenDismissed()) {
      return;
    }
    if (hasSurveyBeenDisplayed()) {
      setShowTrigger(true);
      return;
    }
    const timeout = setTimeout(() => {
      setShowTrigger(true);
      setHasSurveyBeenDisplayed();
    }, timeBeforeDisplaying);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const dismissTrigger = () => {
    setShowTrigger(false);
    setHasSurveyBeenDismissed();
  };
  return React.createElement("div", {
    className: "survey"
  }, React.createElement(CSSTransition, {
    in: showTrigger,
    nodeRef: nodeRef,
    timeout: 300,
    classNames: "trigger",
    unmountOnExit: true
  }, React.createElement("div", {
    ref: nodeRef
  }, React.createElement(UitkCard, {
    backgroundTheme: "secondary",
    padded: true
  }, React.createElement(UitkLayoutFlex, {
    space: "two",
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(UitkHeading, null, formatContent('survey.message.heading', 'Help us improve your experience')), React.createElement(UitkSpacing, {
    padding: {
      blockstart: 'one'
    }
  }, React.createElement("div", null, React.createElement(UitkLink, {
    onClick: () => dismissTrigger(),
    size: "large"
  }, React.createElement("a", {
    href: "https://expediagroup.az1.qualtrics.com/jfe/form/SV_6tISQK4scDnz3ng",
    target: "_black",
    rel: "noreferrer"
  }, formatContent('survey.message.trigger.label', 'Take this 3 minute survey'))))))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkTertiaryButton, {
    size: UitkButtonSize.SMALL,
    onClick: () => dismissTrigger()
  }, React.createElement(UitkIcon, {
    name: "close",
    id: "close",
    size: UitkIconSize.EXTRA_SMALL,
    theme: "secondary"
  }))))))));
};
const hasSurveyBeenDismissed = () => Storage.getItem('hasSurveyBeenDismissed') === 'true';
const hasSurveyBeenDisplayed = () => Storage.getItem('hasSurveyBeenDisplayed') === 'true';
const setHasSurveyBeenDismissed = () => Storage.setItem('hasSurveyBeenDismissed', 'true', COOL_OFF_TIME);
const setHasSurveyBeenDisplayed = () => Storage.setItem('hasSurveyBeenDisplayed', 'true', COOL_OFF_TIME);
export default SurveyTrigger;