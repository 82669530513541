import React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLineSkeleton } from 'uitk-react-skeleton';
const LoadingSkeleton = () => {
  return React.createElement("div", {
    className: "loading-skeleton"
  }, React.createElement(UitkLayoutFlex, {
    space: "six",
    direction: "column"
  }, React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "secondary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "secondary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "secondary"
  })));
};
export default LoadingSkeleton;