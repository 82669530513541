import React from 'react';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkCard } from 'uitk-react-cards';
import { UitkImage, UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';
import { UitkDealMemberBadge } from 'uitk-react-badge';
import { ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
export const BrandSection = ({
  comingSoon,
  invertImageLocation,
  brandName,
  description,
  link,
  cards,
  image,
  title
}) => {
  return React.createElement(UitkLayoutFlexItem, {
    className: "home-spacing"
  }, React.createElement("section", {
    id: "brand-section"
  }, React.createElement(UitkLayoutFlex, {
    className: "marketing-page-width",
    direction: "column",
    alignItems: "center",
    wrap: "wrap"
  }, React.createElement(Viewport, null, React.createElement(ViewMedium, null, React.createElement(React.Fragment, null, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'sixteen'
    }
  }, React.createElement(UitkText, {
    size: 800
  }, title))), React.createElement(UitkLayoutFlexItem, {
    minWidth: 'full_width'
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    direction: invertImageLocation ? 'row-reverse' : 'row',
    justifyContent: 'space-between',
    space: "six",
    alignItems: "center"
  }, React.createElement(UitkLayoutFlexItem, {
    minWidth: 'half_width'
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "six",
    direction: "column",
    alignItems: "start"
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "two",
    alignItems: "center"
  }, React.createElement(UitkText, {
    size: 600
  }, brandName), comingSoon && React.createElement(UitkDealMemberBadge, {
    text: comingSoon
  }))), React.createElement(UitkText, {
    size: 500
  }, description), link.label && React.createElement("div", null, React.createElement(UitkTertiaryButton, {
    tag: "a",
    alignLabelToContent: "start",
    href: link.href
  }, link.label))))), React.createElement(UitkLayoutFlexItem, {
    maxWidth: 'half_width'
  }, React.createElement(UitkFigure, {
    imageFit: "contain",
    ratio: UitkFigureAspectRatioType.R21_9
  }, React.createElement(UitkImage, {
    ...image
  })))))))), React.createElement(ViewSmall, null, React.createElement(React.Fragment, null, React.createElement(UitkLayoutFlexItem, {
    alignSelf: "start"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockstart: 'six'
    }
  }, React.createElement(UitkText, {
    size: 600
  }, title))), React.createElement(UitkLayoutFlexItem, {
    minWidth: 'full_width'
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    direction: invertImageLocation ? 'row-reverse' : 'row',
    justifyContent: 'space-between',
    space: "six",
    wrap: invertImageLocation ? 'wrap-reverse' : 'wrap'
  }, React.createElement(UitkLayoutFlexItem, {
    minWidth: 'full_width'
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "four",
    direction: "column",
    alignItems: "start"
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "two",
    alignItems: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockstart: 'six'
    }
  }, React.createElement(UitkText, {
    size: 600
  }, brandName)), comingSoon && React.createElement(UitkDealMemberBadge, {
    text: comingSoon
  }))), React.createElement(UitkText, {
    size: 500
  }, description), React.createElement("div", null, React.createElement(UitkTertiaryButton, {
    tag: "a",
    alignLabelToContent: "start",
    href: link.href
  }, link.label))))), React.createElement(UitkLayoutFlexItem, {
    maxWidth: 'full_width'
  }, React.createElement(UitkFigure, {
    imageFit: "contain",
    ratio: UitkFigureAspectRatioType.R21_9
  }, React.createElement(UitkImage, {
    ...image
  }))))))))), cards && React.createElement(UitkSpacing, {
    margin: {
      blockstart: 'twelve'
    }
  }, React.createElement(UitkLayoutGrid, {
    columns: {
      small: ['1fr'],
      medium: ['1fr', '1fr', '1fr']
    },
    space: "six"
  }, cards.map(card => React.createElement(UitkLayoutGridItem, {
    key: card.title
  }, React.createElement(UitkCard, {
    border: true,
    padded: true
  }, React.createElement(UitkLayoutFlex, {
    space: "two",
    direction: "column",
    alignItems: "start"
  }, React.createElement(UitkText, {
    size: 600,
    theme: "default"
  }, card.title), React.createElement(UitkText, {
    size: 300,
    theme: "default"
  }, card.description), React.createElement("div", null, React.createElement(UitkTertiaryButton, {
    tag: "a",
    alignLabelToContent: "start",
    href: card.link.href
  }, card.link.label)))))))))));
};
BrandSection.displayName = 'BrandSection';