import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import useAnalytics from '../../hooks/analytics/useAnalytics';
import { useSearchQuery } from '../../graphql/__generated__/types';
import { useHistory } from 'react-router';
import useNavigationParams from 'hooks/useNavigationParams';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import SearchBoxDesktop from 'components/page-header/desktop/search-box-desktop';
import SearchTriggerMobile from 'components/page-header/mobile/search-trigger-mobile';
import { useBexApi } from '@shared-ui/bex-api-context';
import { ElasticSearchAdapter } from '../search/elastic-search-adapter';
export const TypeaheadSearchField = props => {
  const {
    onClick,
    onDismiss,
    searchInputProps
  } = props;
  const {
    icon,
    placeholder
  } = searchInputProps;
  const analytics = useAnalytics();
  const {
    hubId,
    isHubPostfix
  } = useNavigationParams();
  const {
    context
  } = useBexApi();
  const {
    refetch
  } = useSearchQuery({
    variables: {
      context,
      hubId,
      searchTerm: ''
    }
  });
  const searchAdapter = new ElasticSearchAdapter({
    maxResults: 10,
    refetchResults: refetch,
    context,
    hubId
  });
  const {
    push
  } = useHistory();
  const trackSearchInput = debounce(value => {
    if (value) {
      analytics.trackSearchInput({
        searchInput: value
      });
    }
  }, 5000);
  const handleInputChange = event => {
    const {
      value: searchTerm
    } = event.target;
    trackSearchInput(searchTerm);
  };
  const handleSearch = selection => {
    const documentId = isHubPostfix ? `/${hubId}/${selection.term}` : selection.term;
    push(documentId);
  };
  return React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(SearchTriggerMobile, {
    icon: icon,
    searchHandler: handleSearch,
    onInputChange: handleInputChange,
    searchAdapter: searchAdapter,
    label: placeholder,
    typeaheadPlaceholder: placeholder,
    typeaheadEmptyResultPlaceholder: placeholder
  })), React.createElement(ViewLarge, null, React.createElement(SearchBoxDesktop, {
    icon: icon,
    searchHandler: handleSearch,
    onInputChange: handleInputChange,
    searchAdapter: searchAdapter,
    onClick: onClick,
    onDismiss: onDismiss,
    label: placeholder,
    typeaheadPlaceholder: placeholder,
    typeaheadEmptyResultPlaceholder: placeholder
  })));
};
export const ElasticSearch = ({
  searchInputProps
}) => {
  const searchRef = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  // render this on client-side only
  if (!hasMounted) {
    return null;
  }
  return React.createElement("div", {
    ref: searchRef,
    "data-stid": "search",
    className: "search"
  }, React.createElement(TypeaheadSearchField, {
    searchInputProps: searchInputProps,
    onClick: onClickHandler,
    onDismiss: () => {
      if (searchRef.current) searchRef.current.classList.remove('active');
    }
  }));
  function onClickHandler() {
    if (searchRef.current) searchRef.current.classList.add('active');
  }
};