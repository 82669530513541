import React, { useEffect, useState } from 'react';
import { isInViewport } from '../../utils/common';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
import useLocale from '../../hooks/useLocale';
import throttle from 'lodash.throttle';
import useNavigationParams from '../../hooks/useNavigationParams';
const linkSpacingMap = new Map([[1, 'unset'], [2, 'unset'], [3, '0.7rem'], [4, '1.4rem'], [5, '2.1rem'], [6, '2.8rem']]);
const TableOfContent = ({
  contentVersion = 0
}) => {
  const [headings, setHeadings] = useState([]);
  const {
    formatContent
  } = useLocale();
  const {
    documentId
  } = useNavigationParams();
  const getAllHeadings = () => {
    const allHeadings = [];
    // We inlcude headings up to level 3, no more than that
    const htmlHeadings = document.querySelectorAll([1, 2, 3].map(level => `h${level}.md-heading`).join(','));
    // Skip the first heading as it will always be the document title, no need to link to it
    for (let i = 1; i < htmlHeadings.length; i++) {
      const htmlHeading = htmlHeadings.item(i);
      if (htmlHeading.getBoundingClientRect().width > 0) {
        allHeadings.push({
          level: parseInt(htmlHeading.tagName.slice(1), 10),
          text: htmlHeading.textContent,
          id: htmlHeading.id,
          element: htmlHeading,
          tocLinkId: `toc-link-${htmlHeading.id}`
        });
      }
    }
    return allHeadings;
  };
  useEffect(() => {
    var _a;
    const allHeadings = getAllHeadings();
    setHeadings(allHeadings);
    (_a = document.getElementById('document')) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', () => handleScroll(allHeadings));
  }, [documentId, contentVersion]);
  useEffect(() => {
    if (headings.length > 1) {
      handleScroll(headings);
    }
  }, [headings]);
  return headings.length > 1 ? React.createElement("div", null, React.createElement(UitkSpacing, {
    padding: {
      inline: 'six'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "two",
    className: "table-of-content"
  }, React.createElement("div", null, React.createElement(UitkText, {
    size: 200
  }, formatContent('on-this-page'))), headings.map((heading, index) => React.createElement("div", {
    style: {
      paddingInlineStart: linkSpacingMap.get(heading.level)
    },
    key: `${heading.tocLinkId}-${index}`
  }, React.createElement("a", {
    href: `#${heading.id}`,
    id: heading.tocLinkId
  }, React.createElement(UitkText, {
    theme: "default",
    className: "toc-item"
  }, heading.text))))))) : null;
};
const handleScroll = throttle(headings => {
  const currentlyHighlightedElement = headings.filter(heading => {
    var _a, _b;
    (_b = (_a = document.getElementById(heading.tocLinkId)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.contains('toc-active-link');
  })[0];
  // Highlight only the first link from the ones visible on the screen
  let firstHeadingHighlighted = false;
  headings.map(heading => {
    if (isInViewport(heading.element) && !firstHeadingHighlighted) {
      firstHeadingHighlighted = true;
      setHeadingHighlight(heading, true);
    } else {
      setHeadingHighlight(heading, false);
    }
  });
  // If no heading element is being displayed on the screen, keep the last active ToC link
  if (!firstHeadingHighlighted && currentlyHighlightedElement) {
    setHeadingHighlight(currentlyHighlightedElement, true);
  }
}, 20);
const setHeadingHighlight = (heading, isHighlighted) => {
  const linkElement = document.getElementById(heading === null || heading === void 0 ? void 0 : heading.tocLinkId);
  if (!linkElement) {
    return;
  }
  if (isHighlighted) {
    linkElement.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'start'
    });
    if (linkElement.classList) {
      linkElement.classList.add('toc-active-link');
    } else {
      linkElement.className = 'toc-active-link';
    }
  } else {
    linkElement.classList.remove('toc-active-link');
  }
};
export default TableOfContent;