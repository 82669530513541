import { ViewLarge, ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import React from 'react';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkCard } from 'uitk-react-cards';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { MinMax, UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
const LodgingCard = ({
  title,
  description,
  href,
  linkTitle,
  type,
  imageLink
}) => {
  const lodgingContentNode = React.createElement("div", null, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'four'
    }
  }, React.createElement(UitkText, {
    size: 600,
    theme: "emphasis",
    weight: "medium"
  }, title)), React.createElement(UitkSpacing, {
    margin: {
      blockend: 'four'
    }
  }, React.createElement(UitkText, {
    size: 300,
    theme: "default"
  }, description)), React.createElement(UitkTertiaryButton, {
    tag: "a",
    alignLabelToContent: "start",
    href: href
  }, linkTitle));
  const image = React.createElement(UitkFigure, {
    imageFit: type === 'vrbo' ? 'cover' : 'contain',
    style: {
      backgroundColor: '#001193'
    }
  }, React.createElement(UitkImage, {
    alt: '',
    src: imageLink
  }));
  const largeMediumView = React.createElement(UitkSpacing, {
    margin: type === 'vrbo' ? {
      blockstart: 'six'
    } : {
      blockend: 'six'
    }
  }, React.createElement(UitkCard, {
    border: true
  }, React.createElement(UitkLayoutGrid, {
    columns: [MinMax('18x', '1fr'), MinMax('min-content', '2fr')]
  }, React.createElement(UitkLayoutGridItem, null, image), React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(UitkSpacing, {
    padding: {
      block: 'six',
      inline: 'twelve'
    }
  }, React.createElement(UitkLayoutGridItem, null, lodgingContentNode))), React.createElement(ViewMedium, null, React.createElement(UitkSpacing, {
    padding: {
      block: 'six',
      inline: 'twelve'
    }
  }, React.createElement(UitkLayoutGridItem, null, lodgingContentNode)))))));
  return React.createElement(Viewport, null, React.createElement(ViewLarge, null, largeMediumView), React.createElement(ViewMedium, null, largeMediumView), React.createElement(ViewSmall, null, React.createElement("div", null, React.createElement(UitkSpacing, {
    margin: type === 'vrbo' ? {
      blockstart: 'six'
    } : {
      blockend: 'six'
    }
  }, React.createElement(UitkCard, {
    border: true
  }, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutGridItem, null, image)), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    padding: {
      block: 'six',
      inline: 'six'
    }
  }, React.createElement(UitkLayoutGridItem, null, lodgingContentNode)))))))));
};
export default LodgingCard;