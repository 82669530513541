import lunr from 'lunr';
// Register Korean,Japanese and Chinese stemmers in the Pipeline
lunr.Pipeline.registerFunction(word => word, 'stemmer-ko');
lunr.Pipeline.registerFunction(word => word, 'stemmer-jp');
lunr.Pipeline.registerFunction(word => word, 'stemmer-zh');
export class LunrSearchAdapter {
  constructor(indexFileURL, config) {
    this.indexFileURL = indexFileURL;
    this.config = config;
    this.categories = {};
    this.results = [];
    this.index = null;
  }
  onItemSelection(data) {
    return Promise.resolve(data);
  }
  getResults() {
    return this.results;
  }
  async fetchResponse() {
    return Promise.resolve({
      sr: []
    });
  }
  adaptResponseToTypeaheadResults() {
    return new Array();
  }
  async getSearchIndex(indexFileURL) {
    if (this.index === null) {
      const response = await fetch(indexFileURL);
      this.index = lunr.Index.load(await response.json());
    }
    return this.index;
  }
  async fetchResults(term) {
    var _a;
    const searchIndex = await this.getSearchIndex(this.indexFileURL);
    this.results = await search(term, searchIndex, (_a = this.config) === null || _a === void 0 ? void 0 : _a.maxResults);
    return this.results;
  }
  async fetchResultsCategorized(term) {
    var _a;
    const searchIndex = await this.getSearchIndex(this.indexFileURL);
    this.results = await search(term, searchIndex, (_a = this.config) === null || _a === void 0 ? void 0 : _a.maxResults);
    return groupSearchResults(this.results);
  }
  fetchRecentSearchResults() {
    throw new Error('Method not implemented.');
  }
  fetchRecentSearchResultsCategorized() {
    throw new Error('Method not implemented.');
  }
  isHistoryDetailEnabled() {
    return false;
  }
  fetchAlternativeResults() {
    return Promise.resolve([]);
  }
  logResponse(results) {
    // console.debug('Search box response', results);
    return Promise.resolve(results);
  }
  typeaheadDialogOpened() {}
  logSuggestionShown() {}
  logTypeaheadDismiss() {}
  setTypeaheadCategories() {}
  getTypeaheadCategories() {
    return this.categories;
  }
}
async function search(term, index, maxResults) {
  if (!term) {
    return [];
  }
  /*
  Escape Lunr-specific modifiers from the search input
  reference : https://lunrjs.com/docs/lunr.Index.html
  */
  const sanitizedTerm = term.replace(/[~^:]|^-|^\+/g, match => `\\${match}`);
  const matchingDocuments = await index.search(`${sanitizedTerm.trim()}^100 ${sanitizedTerm.trim()}*^50 ${sanitizedTerm.trim()}~2`);
  return matchingDocuments.slice(0, maxResults !== null && maxResults !== void 0 ? maxResults : matchingDocuments.length).map(matchingDocument => {
    var _a;
    const documentInfo = JSON.parse(matchingDocument.ref);
    return {
      name: `<div class="search-result-location">${(_a = documentInfo === null || documentInfo === void 0 ? void 0 : documentInfo.parentsTitles) === null || _a === void 0 ? void 0 : _a.replace(/\//g, '<span class="search-result-location-separator"> › </span>')}</div>`,
      title: documentInfo.title,
      value: documentInfo.path,
      icon: null,
      subtext: `<span class="search-result-title">${documentInfo.title}</span>: ${documentInfo.intro || ''}`
    };
  });
}
function groupSearchResults(results) {
  const groupedResults = [];
  for (const result of results) {
    // Grouping only happens at the root content level, e.g.: '/rapid/setup/hello' -> 'rapid'
    const heading = capitalizeFirstCharacter(extractContentRoot(result.value));
    const groupIndex = groupedResults.findIndex(group => group.heading === heading);
    if (groupIndex === -1) {
      groupedResults.push({
        heading,
        typeaheadResults: [result]
      });
    } else {
      groupedResults[groupIndex].typeaheadResults.push(result);
    }
  }
  return groupedResults;
}
function extractContentRoot(contentPath) {
  var _a, _b;
  const match = /^\/?(?<contentRoot>[^/]+)/.exec(contentPath);
  return (_b = (_a = match === null || match === void 0 ? void 0 : match.groups) === null || _a === void 0 ? void 0 : _a.contentRoot) !== null && _b !== void 0 ? _b : null;
}
function capitalizeFirstCharacter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}