import { useState, useEffect } from 'react';
import { initializeKAIChatbot, initializeZendeskChatbot } from './utils';
export const useChatbotInitializer = chatbot => {
  const [KAIinitialized, setKAIinitialized] = useState(false);
  const [zendeskInitialized, setZendeskInitialized] = useState(false);
  useEffect(() => {
    if (chatbot) {
      const {
        __typename: chatbotType
      } = chatbot;
      if (chatbotType === 'IntelligentAgentChatbotResponse' && !KAIinitialized) {
        initializeKAIChatbot(chatbot).then(() => {
          setKAIinitialized(true);
        });
      } else if (chatbotType === 'ZendeskChatbotResponse' && !zendeskInitialized) {
        initializeZendeskChatbot(chatbot).then(() => {
          setZendeskInitialized(true);
        });
      }
    }
  }, [chatbot]);
  if (chatbot) {
    const {
      __typename: chatbotType
    } = chatbot;
    if (chatbotType === 'IntelligentAgentChatbotResponse') return KAIinitialized;else if (chatbotType === 'ZendeskChatbotResponse') return zendeskInitialized;
  } else return false;
};