import { useDialog } from '@shared-ui/dialog-context';
import React, { useEffect } from 'react';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkDialog, UitkDialogContent } from 'uitk-react-dialog';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkParagraph } from 'uitk-react-text';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkLoader } from 'uitk-react-loader';
export const BaseDialog = ({
  dialogId,
  dialogLabel,
  headerText,
  bodyText,
  buttonText,
  closeDialogHandler,
  openDialogHandler,
  loading,
  textCentered,
  isDismissable = true
}) => {
  const [isDialogOpen, dialogActions, DialogComponent] = useDialog(dialogId, 'dialog', isDismissable);
  const closeDialog = () => {
    if (closeDialogHandler) {
      closeDialogHandler();
    }
    dialogActions.closeDialog();
  };
  useEffect(() => {
    if (openDialogHandler && isDialogOpen) {
      openDialogHandler();
    }
  }, [isDialogOpen]);
  return React.createElement(DialogComponent, null, React.createElement(UitkDialog, {
    ariaLabel: dialogLabel,
    returnFocusOnClose: true,
    dialogShow: isDialogOpen
  }, React.createElement(UitkDialogContent, {
    key: "dialogContent"
  }, React.createElement(UitkLayoutFlex, {
    space: "two",
    direction: "column"
  }, React.createElement(React.Fragment, null, headerText && React.createElement(UitkLayoutFlexItem, {
    alignSelf: textCentered ? 'center' : 'start'
  }, React.createElement(UitkHeading, {
    tag: "h2",
    size: 8,
    key: "dialogHeading"
  }, headerText)), bodyText && React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement(UitkParagraph, {
    key: "dialogParagraph",
    size: 2
  }, bodyText)), loading && React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement(UitkLoader, null)), buttonText && React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      blockstart: 'two'
    }
  }, React.createElement(UitkPrimaryButton, {
    onClick: closeDialog
  }, buttonText))))))));
};