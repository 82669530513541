import React from 'react';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkCard } from 'uitk-react-cards';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
const SolutionCards = ({
  cards,
  title
}) => {
  return React.createElement(UitkLayoutFlexItem, {
    className: "home-spacing"
  }, React.createElement("section", {
    id: "solutions-section"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockstart: 'four'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    className: "marketing-page-width"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'twelve'
    }
  }, React.createElement(UitkText, {
    size: 700,
    align: "center"
  }, title))), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "stretch"
  }, React.createElement(UitkLayoutGrid, {
    columns: {
      small: 1,
      medium: 2
    },
    className: "cards",
    space: "six"
  }, cards.map((card, index) => React.createElement(UitkLayoutGridItem, {
    key: index
  }, React.createElement(UitkCard, {
    border: true,
    padded: true
  }, React.createElement(UitkLayoutFlex, {
    space: "two",
    direction: "column",
    alignItems: "start",
    justifyContent: "space-between"
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'two'
    }
  }, React.createElement(UitkText, {
    size: 600,
    theme: "default"
  }, card.heading)), React.createElement(UitkText, {
    size: 300,
    theme: "default"
  }, card.subHeading))), React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "two",
    wrap: "wrap"
  }, card.buttons.map(button => React.createElement(UitkSpacing, {
    key: button.label
  }, React.createElement(UitkTertiaryButton, {
    href: button.href,
    alignLabelToContent: "start"
  }, button.label)))))))))))))));
};
export default SolutionCards;