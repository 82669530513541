import React from 'react';
import { UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { SidebarBadge } from './sidebar-badge';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkSpacing } from 'uitk-react-spacing';
const SidebarButton = ({
  label,
  isActive,
  onClick,
  badge,
  icon
}) => {
  return React.createElement(UitkTertiaryButton, {
    size: UitkButtonSize.SMALL,
    isFullWidth: true,
    onClick: onClick,
    "aria-label": label,
    className: isActive && 'sidebar-active-link'
  }, React.createElement(UitkLayoutFlex, {
    direction: "row",
    alignContent: "center",
    alignItems: "center"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("span", null, label)), icon && React.createElement(UitkSpacing, {
    padding: {
      inlinestart: 'one'
    }
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkIcon, {
    description: icon.description,
    name: icon.token,
    id: icon.id,
    theme: "default",
    title: icon.title,
    size: UitkIconSize.EXTRA_SMALL
  }))), badge ? React.createElement(UitkLayoutFlexItem, {
    alignSelf: "start"
  }, React.createElement("span", null, React.createElement(SidebarBadge, {
    text: badge.text,
    size: badge.size,
    theme: badge.theme
  }))) : null));
};
export default SidebarButton;