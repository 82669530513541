import React from 'react';
import { UitkSpacing, UitkSpacingHr } from 'uitk-react-spacing';
import { PartnerAccountDetails } from './partner-account-details';
import { AuthenticatedUserSubMenuButton } from './authenticated-user-menu-button';
import { AuthenticatedUserSubMenu } from './authenticated-user-sub-menu';
export const AuthenticatedUserMenuContent = ({
  menu
}) => {
  return React.createElement(React.Fragment, null, menu.subMenus.map((subMenu, index) => {
    if (subMenu.__typename !== 'DocumentationHeaderSubMenu') {
      return null;
    }
    if (index === 0) {
      return React.createElement(React.Fragment, null, React.createElement(PartnerAccountDetails, {
        subMenu: subMenu
      }), React.createElement(UitkSpacing, {
        padding: {
          blockstart: 'two'
        }
      }, React.createElement("div", null, React.createElement(UitkSpacingHr, {
        blockMargin: "unset"
      }))));
    }
    return React.createElement(React.Fragment, null, React.createElement(AuthenticatedUserSubMenu, {
      subMenu: subMenu
    }), React.createElement(UitkSpacing, null, React.createElement("div", null, React.createElement(UitkSpacingHr, {
      blockMargin: "unset"
    }))));
  }), menu.buttons.map((button, index) => {
    return React.createElement(AuthenticatedUserSubMenuButton, {
      button: button,
      underlined: index < menu.buttons.length - 1
    });
  }));
};