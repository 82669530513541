import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { CopyAction } from '../../copy-action/copy-action';
import { UitkText, UitkHeading } from 'uitk-react-text';
import { UitkSpacing } from 'uitk-react-spacing';
export const PartnerAccountDetails = ({
  subMenu
}) => {
  var _a, _b, _c, _d, _e, _f;
  const currentPartnerName = (_b = (_a = subMenu.items) === null || _a === void 0 ? void 0 : _a[0].title) !== null && _b !== void 0 ? _b : '';
  const currentPartnerType = (_d = (_c = subMenu.items) === null || _c === void 0 ? void 0 : _c[1].title) !== null && _d !== void 0 ? _d : '';
  const currentPartnerId = (_f = (_e = subMenu.items) === null || _e === void 0 ? void 0 : _e[2].title) !== null && _f !== void 0 ? _f : '';
  return React.createElement(UitkSpacing, {
    padding: {
      inlinestart: 'six',
      inlineend: 'one'
    }
  }, React.createElement("div", null, React.createElement(UitkHeading, {
    tag: "h2",
    size: 5
  }, currentPartnerName), React.createElement(UitkText, {
    theme: "default",
    spacing: "two",
    weight: "medium"
  }, currentPartnerType), React.createElement(UitkLayoutFlex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    theme: "default",
    spacing: "two"
  }, currentPartnerId)), React.createElement(UitkLayoutFlexItem, null, React.createElement(CopyAction, {
    copyValue: currentPartnerId.split(': ')[1]
  })))));
};