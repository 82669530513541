import React, { useEffect } from 'react';
import { useChatbotDataQuery } from '../../graphql/__generated__/types';
import { useBexApi } from '@shared-ui/bex-api-context';
import useNavigationParams from '../../hooks/useNavigationParams';
import ChatbotButton from './chatbot-button/chatbot-button';
import { useChatbotInitializer } from './use-chatbot-initializer';
import useTabContext from './../../hooks/useTabContext';
import { closeChatbot, updateZendeskSettings } from './utils';
const Chatbot = () => {
  var _a;
  const {
    hubId
  } = useNavigationParams();
  const {
    currentTabId
  } = useTabContext();
  const {
    context
  } = useBexApi();
  const {
    data,
    loading,
    error: chatBotError,
    refetch
  } = useChatbotDataQuery({
    variables: {
      hubId,
      context,
      tabId: currentTabId
    },
    ssr: false
  });
  const chatbotInitialized = useChatbotInitializer((_a = data === null || data === void 0 ? void 0 : data.documentation) === null || _a === void 0 ? void 0 : _a.chatbot);
  useEffect(() => {
    refetch({
      hubId,
      context,
      tabId: currentTabId
    });
    closeChatbot();
  }, [currentTabId]);
  if (chatBotError) {
    // eslint-disable-next-line no-console
    console.error('Error fetching chatbot data:', chatBotError);
    return null;
  }
  if (loading || !data) return null;
  const chatbot = data.documentation.chatbot;
  if (chatbot) {
    const {
      __typename: chatbotType
    } = chatbot;
    if (chatbotType === 'NoConfiguration') return null;
    if (chatbotType === 'ZendeskChatbotResponse' && chatbotInitialized) updateZendeskSettings(chatbot.formIds);
    return chatbotInitialized ? React.createElement(ChatbotButton, {
      chatbot: chatbot
    }) : null;
  }
  return null;
};
export default Chatbot;