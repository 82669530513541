import React, { useState } from 'react';
import { UitkMenuList } from 'uitk-react-menu';
import { SELECTED_PARTNER_ID_COOKIE_EXPIRATION_DAYS } from './../../../hapi-plugins/auth/utils';
import { useHistory } from 'react-router-dom';
import { Overlay } from './../../common/overlay';
import { PartnerSwitchDialog } from './../../dialog/partner-switch-dialog';
const authorizeUrl = '/eg/auth/custom/authorize';
const getPartnerAccountId = authUrl => {
  const partnerSearchParam = 'assumed_partner_account_id=';
  return authUrl.substring(authUrl.indexOf(partnerSearchParam) + partnerSearchParam.length);
};
const setPartnerAccountIdCookie = partnerAccountId => {
  const hostName = window.location.hostname;
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + SELECTED_PARTNER_ID_COOKIE_EXPIRATION_DAYS);
  document.cookie = `SELECTED_PARTNER_ACCOUNT_ID=${partnerAccountId}; secure=true; path=/; sameSite=None; domain=.${hostName}; expires=${expirationDate}`;
};
const prepareAuthorizationURL = partnerId => {
  const pathName = location.pathname;
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append('redirect_uri', pathName.endsWith('/') ? pathName.substring(0, pathName.length - 1) : pathName);
  urlSearchParams.append('assumed_partner_account_id', partnerId);
  return `${authorizeUrl}?${urlSearchParams.toString()}`;
};
export const AuthenticatedUserSubMenu = ({
  subMenu
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const switchPartnerAccount = authUrl => {
    setIsLoading(true);
    history.push({
      search: '?dialog=partner-switch'
    });
    const partnerId = getPartnerAccountId(authUrl);
    window.location.href = prepareAuthorizationURL(partnerId);
    setPartnerAccountIdCookie(partnerId);
  };
  const getSubMenuItems = () => {
    return subMenu.items.map(item => {
      if (item.__typename === 'DocumentationHeaderMenuLinkItem') return {
        label: item.title,
        className: 'uitk-list-item',
        onClick: () => switchPartnerAccount(item.action.resource.value)
      };
    });
  };
  return React.createElement(React.Fragment, null, React.createElement(UitkMenuList, {
    items: getSubMenuItems(),
    title: subMenu === null || subMenu === void 0 ? void 0 : subMenu.title
  }), isLoading && React.createElement(Overlay, null), React.createElement(PartnerSwitchDialog, null));
};