import React, { createContext, useState } from 'react';
export const SideBarVisibilityContext = createContext(null);
const SideBarVisibilityContextProvider = ({
  children
}) => {
  const [hideSideBar, setHideSideBar] = useState(false);
  const [expandMobileSideBarMenu, setExpandMobileSideBarMenu] = useState(false);
  const toggleMobileSideBarMenu = () => {
    setExpandMobileSideBarMenu(prevState => !prevState);
  };
  return React.createElement(SideBarVisibilityContext.Provider, {
    value: {
      hideSideBar,
      setHideSideBar,
      toggleMobileSideBarMenu,
      expandMobileSideBarMenu,
      setExpandMobileSideBarMenu
    }
  }, children);
};
export default SideBarVisibilityContextProvider;