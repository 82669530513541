/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { openChatbot } from './../utils';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import useLocale from './../../../hooks/useLocale';
const ChatbotButton = ({
  chatbot
}) => {
  const [isTexthidden, setTextToogle] = useState(true);
  const {
    formatContent
  } = useLocale();
  const buttonTitle = chatbot.button.primary;
  const buttonIcon = chatbot.button.icon.token;
  return React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(UitkLayoutPosition, {
    type: "absolute",
    position: {
      bottom: 'twelve',
      right: 'six'
    }
  }, React.createElement(UitkPrimaryButton, {
    onMouseOver: () => setTextToogle(false),
    onMouseLeave: () => setTextToogle(true),
    onClick: () => {
      openChatbot(chatbot);
    },
    "aria-label": formatContent('chatbot.aria.label')
  }, React.createElement(UitkIcon, {
    name: buttonIcon
  }), !isTexthidden && buttonTitle))), React.createElement(ViewSmall, null, React.createElement(UitkLayoutPosition, {
    type: "absolute",
    position: {
      bottom: 'twelve',
      right: 'three'
    }
  }, React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.SMALL,
    onClick: () => {
      openChatbot(chatbot);
    },
    "aria-label": formatContent('chatbot.aria.label')
  }, React.createElement(UitkIcon, {
    name: buttonIcon
  }), !isTexthidden && buttonTitle))));
};
export default ChatbotButton;