import React, { useCallback, useState } from 'react';
import { UitkButtonSize, UitkOverlayButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkText } from 'uitk-react-text';
const CopyButton = React.memo(function CopyButton(props) {
  return React.createElement(UitkTertiaryButton, {
    className: "copy-button",
    ...props
  }, React.createElement(UitkIcon, {
    name: "copy_content",
    size: UitkIconSize.SMALL
  }));
});
const CopiedButton = React.memo(function CopiedButton(props) {
  return React.createElement(UitkOverlayButton, {
    size: UitkButtonSize.SMALL,
    ...props
  }, React.createElement(UitkText, {
    theme: "inverse",
    size: 200
  }, 'Copied'), React.createElement(UitkIcon, {
    name: "check",
    size: UitkIconSize.SMALL
  }));
});
export const CopyAction = ({
  copyValue
}) => {
  const [Button, setButton] = useState(CopyButton);
  const copySnippetToClipboard = useCallback(e => {
    navigator.clipboard.writeText(copyValue);
    toggleCopyButton(e);
  }, []);
  const toggleCopyButton = useCallback(e => {
    e.stopPropagation();
    setButton(CopiedButton);
    setTimeout(() => {
      setButton(CopyButton);
    }, 3000);
  }, []);
  return React.createElement(Button, {
    onClick: e => copySnippetToClipboard(e)
  });
};