import React, { useState } from 'react';
import { TypeaheadRenderType, UitkTypeahead } from 'uitk-react-typeahead';
import { UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
const SearchTriggerMobile = props => {
  const {
    searchAdapter,
    searchHandler,
    icon,
    onInputChange,
    label,
    typeaheadEmptyResultPlaceholder,
    typeaheadPlaceholder
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  return React.createElement(UitkTypeahead, {
    id: icon.id,
    name: icon.title,
    icon: icon.token,
    label: label,
    onUpdate: searchHandler,
    onInputChange: onInputChange,
    customTrigger: {
      showTypeahead: isOpen
    },
    onDismiss: () => setIsOpen(false),
    renderType: TypeaheadRenderType.ADAPTIVE,
    typeaheadEmptyResultPlaceholder: typeaheadEmptyResultPlaceholder,
    typeaheadPlaceholder: typeaheadPlaceholder,
    adapter: searchAdapter || {} || {},
    allowExactMatch: true,
    hasClearButton: true
  }, React.createElement(UitkTertiaryButton, {
    onClick: () => {
      setIsOpen(true);
    },
    size: UitkButtonSize.LARGE
  }, React.createElement(UitkIcon, {
    name: "search",
    theme: "default"
  })));
};
export default SearchTriggerMobile;