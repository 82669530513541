import React from 'react';
import SignInButton from '../sign-in-button';
import { AuthenticatedUserMenuDesktop } from './authenticated-user-menu-desktop';
export const AuthenticationDesktop = ({
  authentication
}) => {
  if (authentication.menu) {
    return React.createElement(AuthenticatedUserMenuDesktop, {
      menu: authentication.menu
    });
  }
  return React.createElement(SignInButton, {
    auth: authentication
  });
};