import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { useBexApi } from '@shared-ui/bex-api-context';
import useNavigationParams from '../../hooks/useNavigationParams';
import { useRelationshipsQuery } from '../../graphql/__generated__/types';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing } from 'uitk-react-spacing';
const RelatedSection = () => {
  const {
    hubId,
    documentId
  } = useNavigationParams();
  const {
    context
  } = useBexApi();
  const {
    data,
    loading,
    error
  } = useRelationshipsQuery({
    variables: {
      context,
      hubId,
      documentId
    }
  });
  if (error || loading || !data) {
    return null;
  }
  const relationships = data.documentation.relationships;
  const related = relationships.related;
  const relatedTitle = related === null || related === void 0 ? void 0 : related.title;
  const relatedElements = related === null || related === void 0 ? void 0 : related.elements;
  if (!relatedElements || relatedElements.length === 0) {
    return React.createElement(React.Fragment, null);
  }
  return React.createElement(UitkSpacing, {
    padding: {
      inlinestart: 'three'
    },
    border: ['inlinestart']
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "three",
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkHeading, {
    tag: "h2"
  }, React.createElement(UitkText, {
    theme: "default",
    inline: true,
    size: 400
  }, relatedTitle.text))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      inlinestart: 'one'
    }
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "two",
    direction: "column"
  }, relatedElements.map(element => {
    var _a;
    return React.createElement(UitkLayoutFlexItem, {
      key: `${element.title.text}${(_a = element.subtitle) === null || _a === void 0 ? void 0 : _a.text}`
    }, React.createElement(UitkLink, {
      className: "link-decoration"
    }, React.createElement("a", {
      href: element.url.resource.value
    }, React.createElement("div", null, React.createElement(UitkHeading, {
      tag: "h3"
    }, React.createElement(UitkText, {
      theme: "default",
      inline: true
    }, element.title.text))))));
  }))))))));
};
export default RelatedSection;