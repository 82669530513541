import { ViewLarge, ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import React from 'react';
import { UitkLayoutFlexItem, UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLayoutGrid, MinMax } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
import LodgingCard from './lodging-card';
const LodgingSection = ({
  formatContent
}) => {
  const connectivityHubCard = React.createElement(LodgingCard, {
    title: formatContent('brandsSection.Supply.cards.connectivityHub'),
    description: formatContent('brandsSection.Supply.cards.connectivityHub.description'),
    href: "https://developers.expediagroup.com/supply/lodging",
    linkTitle: formatContent('brandsSection.Supply.cards.connectivityHub.linkText'),
    type: "expedia",
    imageLink: "https://a.travel-assets.com/documentation-hubs/docs/static-assets/images/expedia-logo.svg"
  });
  const vrboHubNode = React.createElement(LodgingCard, {
    title: formatContent('brandsSection.Supply.cards.vrbo'),
    description: formatContent('brandsSection.Supply.cards.vrbo.description'),
    href: "https://integration-central.vrbo.com/t",
    linkTitle: formatContent('brandsSection.Supply.cards.vrbo.linkText'),
    type: "vrbo",
    imageLink: "https://a.travel-assets.com/documentation-hubs/docs/static-assets/images/vrbo-logo.svg"
  });
  return React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(UitkLayoutFlexItem, {
    dir: "column",
    className: "home-spacing marketing-page-width"
  }, React.createElement(UitkLayoutFlex, {
    direction: "row",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, connectivityHubCard), React.createElement(UitkLayoutFlexItem, null, vrboHubNode))), React.createElement(UitkLayoutFlexItem, {
    dir: "column"
  }, React.createElement(UitkSpacing, {
    margin: {
      inlinestart: 'twelve'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    justifyContent: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'six'
    }
  }, React.createElement(UitkText, {
    size: 600,
    theme: "emphasis"
  }, formatContent('brandsSection.Supply'))), React.createElement(UitkLayoutGrid, {
    columns: [MinMax('40x', '70x'), '1fr', 'min-content'],
    space: "two"
  }, React.createElement(UitkText, {
    size: 500,
    theme: "default"
  }, formatContent('brandsSection.Supply.description'))))))))), React.createElement(ViewMedium, null, React.createElement(UitkLayoutFlexItem, {
    dir: "column",
    className: "home-spacing marketing-page-width"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, {
    dir: "column"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'twelve'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    justifyContent: "center",
    alignItems: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'six'
    }
  }, React.createElement(UitkText, {
    size: 600,
    theme: "emphasis"
  }, formatContent('brandsSection.Supply'))), React.createElement(UitkText, {
    size: 500,
    theme: "default"
  }, formatContent('brandsSection.Supply.description'))))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, connectivityHubCard), React.createElement(UitkLayoutFlexItem, null, vrboHubNode)))))), React.createElement(ViewSmall, null, React.createElement(UitkLayoutFlexItem, {
    dir: "column",
    className: "home-spacing marketing-page-width"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, {
    dir: "column"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'twelve'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    justifyContent: "center",
    alignItems: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'six'
    }
  }, React.createElement(UitkText, {
    size: 600,
    theme: "emphasis"
  }, formatContent('brandsSection.Supply'))), React.createElement(UitkText, {
    size: 300,
    theme: "default"
  }, formatContent('brandsSection.Supply.description'))))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, connectivityHubCard), React.createElement(UitkLayoutFlexItem, null, vrboHubNode)))))));
};
export default LodgingSection;