import React from 'react';
import { useLocation } from 'react-router-dom';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import LanguageSelectorMobile from 'components/page-header/mobile/language-selector-mobile';
import LanguageSelectorDesktop from 'components/page-header/desktop/language-selector-desktop';
const LanguageSelector = ({
  menu
}) => {
  const {
    subMenus,
    leadingIcon,
    trailingIcon
  } = menu;
  const languages = subMenus[0].items;
  const location = useLocation();
  const currentLanguage = languages.filter(language => language.icon !== null)[0];
  const getLanguageMenuItem = languageItem => ({
    className: 'uitk-list-item',
    label: languageItem.title,
    typeAnchor: true,
    attributes: {
      role: 'menuItem'
    },
    selected: languageItem.title === currentLanguage.title,
    href: `${location.pathname}?locale=${languageItem.label}`
  });
  const languageMenuItems = languages.map(language => getLanguageMenuItem(language));
  return React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(LanguageSelectorMobile, {
    languageMenuItems: languageMenuItems,
    iconName: leadingIcon.token
  })), React.createElement(ViewLarge, null, React.createElement(LanguageSelectorDesktop, {
    languageMenuItems: languageMenuItems,
    currentLanguage: currentLanguage,
    primaryIconName: leadingIcon.token,
    secondaryIconName: trailingIcon.token
  })));
};
export default LanguageSelector;