import React, { createContext, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const TabContext = createContext({
  currentTabId: '',
  onTabIdChange: () => {}
});
const TabContextProvider = ({
  children
}) => {
  const [currentTabId, setTabId] = useState('');
  const onTabIdChange = id => {
    setTabId(id);
  };
  return React.createElement(TabContext.Provider, {
    value: {
      currentTabId,
      onTabIdChange
    }
  }, children);
};
export default TabContextProvider;