import React from 'react';
import SidebarButton from '../sidebar-button/sidebar-button';
const SidebarExpandoTitle = ({
  label,
  isHighlighted,
  onClick,
  badge
}) => React.createElement(SidebarButton, {
  label: label,
  isActive: isHighlighted,
  onClick: onClick,
  badge: badge
});
export default SidebarExpandoTitle;