import React from 'react';
import { useHistory } from 'react-router-dom';
import { getPathName } from '../../utils/common';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
const NavigatorLink = ({
  link,
  alignment
}) => {
  const {
    push
  } = useHistory();
  const documentLink = link === null || link === void 0 ? void 0 : link.action.resource.value;
  const onClickHandler = e => {
    e.preventDefault();
    push(getPathName(documentLink));
  };
  return React.createElement(UitkLink, {
    size: "large",
    align: alignment,
    className: "navigator-link"
  }, React.createElement("a", {
    href: documentLink,
    onClick: onClickHandler
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    style: {
      cursor: 'pointer'
    },
    direction: alignment === 'left' ? 'row-reverse' : 'row'
  }, React.createElement(UitkLayoutFlex, null, React.createElement(UitkSpacing, {
    padding: alignment === 'right' ? {
      inlineend: 'two'
    } : {
      inlinestart: 'two'
    },
    border: [alignment === 'right' ? 'inlineend' : 'inlinestart']
  }, React.createElement("div", null, React.createElement(UitkText, {
    align: alignment,
    weight: "regular"
  }, link.icon.title), React.createElement("span", null, link.text)))), React.createElement(UitkLayoutFlex, {
    alignItems: "center"
  }, React.createElement("div", null, React.createElement(UitkIcon, {
    theme: "default",
    id: link.icon.title,
    title: link.icon.title,
    description: link.icon.description,
    name: link.icon.token,
    size: UitkIconSize.LARGE
  })))))));
};
export default NavigatorLink;