import React from 'react';
import { UitkSpacing, UitkSpacingHr } from 'uitk-react-spacing';
import SidebarSectionElements from './sidebar-section-elements';
import { UitkText } from 'uitk-react-text';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { SidebarBadge } from '../sidebar-button/sidebar-badge';
const SidebarSection = ({
  section,
  withBottomBorder
}) => {
  const {
    heading,
    navigationElements,
    icon,
    badge
  } = section;
  return React.createElement(React.Fragment, null, heading && React.createElement(UitkSpacing, {
    padding: {
      inlinestart: icon ? 'one' : 'two',
      blockend: 'two'
    },
    margin: {
      inlinestart: icon ? 'half' : 'unset'
    }
  }, React.createElement(UitkLayoutFlex, {
    space: "half"
  }, icon && React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkIcon, {
    description: icon.description,
    name: icon.token,
    id: icon.id,
    theme: "default",
    title: icon.title,
    size: UitkIconSize.EXTRA_SMALL
  })), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    weight: "medium"
  }, heading, badge ? React.createElement(SidebarBadge, {
    text: badge.text,
    size: badge.size,
    theme: badge.theme
  }) : null)))), React.createElement(SidebarSectionElements, {
    navigationElements: navigationElements
  }), withBottomBorder && React.createElement(UitkSpacing, {
    padding: {
      large: {
        inlinestart: 'two'
      }
    }
  }, React.createElement("div", null, React.createElement(UitkSpacingHr, null))));
};
export default SidebarSection;