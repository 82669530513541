import React from 'react';
import MessageBanner from 'components/common/message-banner';
import useMessageBanner from '../../hooks/useMessageBanner';
import TabContextProvider from '../../contexts/tab-context';
import Chatbot from '../chatbot/chatbot';
const HomePageLayout = ({
  header,
  sidebar,
  document,
  hideSideBar
}) => {
  const {
    messageBanner
  } = useMessageBanner();
  return React.createElement(TabContextProvider, null, React.createElement("div", {
    id: "home-page-layout"
  }, React.createElement("header", {
    id: "page-header"
  }, header), messageBanner && React.createElement(MessageBanner, {
    ...messageBanner
  }), React.createElement("main", {
    id: "page-body"
  }, sidebar === null ? null : React.createElement("section", {
    id: "sidebar",
    className: hideSideBar ? 'no-border' : ''
  }, sidebar), React.createElement("section", {
    id: "document"
  }, document)), React.createElement(Chatbot, null)));
};
export default HomePageLayout;