import React, { useState } from 'react';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { UitkMenuList } from 'uitk-react-menu';
import { UitkMenu, UitkMenuContainer, UitkMenuTrigger } from 'uitk-react-menu';
const LanguageSelectorMobile = ({
  languageMenuItems,
  iconName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return React.createElement(UitkMenu, {
    isOpen: isOpen,
    onTriggerClick: () => setIsOpen(prevState => !prevState)
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkTertiaryButton, {
    size: UitkButtonSize.LARGE,
    name: "language-selection",
    "data-testid": "language-selection-button"
  }, React.createElement(UitkIcon, {
    name: iconName,
    size: UitkIconSize.LARGE,
    theme: "default"
  }))), React.createElement(UitkMenuContainer, {
    className: "language-selection-menu"
  }, React.createElement(UitkMenuList, {
    items: languageMenuItems
  })));
};
export default LanguageSelectorMobile;