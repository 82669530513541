let virtualAgentControl;
const initializeKAIChatbot = chatbot => {
  return new Promise((resolve, reject) => {
    const {
      authToken,
      scriptUrl,
      channelOriginId
    } = chatbot;
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.type = 'text/javascript';
    script.defer = false;
    script.onload = async () => {
      try {
        const {
          VirtualAgentControl
        } = window;
        await VirtualAgentControl.initialize(authToken, channelOriginId);
        const vac = await VirtualAgentControl.createFrame({
          autoOpen: false
        });
        vac.start({
          mode: 'CREATE_CONVERSATION'
        }, {
          participantType: 'Traveler'
        });
        virtualAgentControl = vac;
        resolve(true);
      } catch (error) {
        console.error('Intelligent agent chatbot initialization error:', error);
        reject(error);
      }
    };
    script.onerror = () => {
      reject(new Error('KAI chatbot script failed to load'));
    };
    document.head.appendChild(script);
  });
};
const initializeZendeskChatbot = chatbot => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${chatbot.key}`;
    script.type = 'text/javascript';
    script.defer = false;
    script.id = 'ze-snippet';
    script.onload = () => {
      try {
        window.zE('webWidget', 'hide');
        window.zE('webWidget:on', 'close', () => {
          window.zE('webWidget', 'hide');
        });
        resolve(true);
      } catch (error) {
        console.error('Zendesk chatbot initialization error:', error);
        reject(error);
      }
    };
    script.onerror = () => {
      reject(new Error('Zendesk chatbot script failed to load'));
    };
    document.head.appendChild(script);
  });
};
const openKAIWidget = () => {
  virtualAgentControl === null || virtualAgentControl === void 0 ? void 0 : virtualAgentControl.openChatWidget();
};
const closeKAIWidget = () => {
  virtualAgentControl === null || virtualAgentControl === void 0 ? void 0 : virtualAgentControl.closeChatWidget();
};
const openChatbot = chatbot => {
  const {
    __typename: chatbotType
  } = chatbot;
  if (chatbotType === 'ZendeskChatbotResponse') openZendeskChatbot();else if (chatbotType === 'IntelligentAgentChatbotResponse') openKAIWidget();
};
const openZendeskChatbot = () => {
  window.zE('webWidget', 'show');
  window.zE('webWidget', 'open');
};
const closeChatbot = () => {
  closeKAIWidget();
  closeZendeskChatbot();
};
const closeZendeskChatbot = () => {
  try {
    window.zE('webWidget', 'hide');
  } catch (e) {
    console.log(e.message);
  }
};
const updateZendeskSettings = formIds => {
  window.zE('webWidget', 'updateSettings', {
    webWidget: {
      color: {
        theme: '#545CFF'
      },
      contactForm: {
        ticketForms: formIds.map(formId => ({
          id: formId
        }))
      }
    }
  });
};
export { initializeKAIChatbot, initializeZendeskChatbot, openChatbot, closeChatbot, updateZendeskSettings };