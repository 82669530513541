import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UitkTab, UitkTabs, UitkTabsType } from 'uitk-react-tabs';
import { UiLinkTarget } from '../../graphql/__generated__/types';
import { filterHeaderLinks, getActiveTabIndex } from 'utils/header-navigation';
import useNavigationParams from 'hooks/useNavigationParams';
import { getPathName } from '../../utils/common';
import useTabContext from '../../hooks/useTabContext';
const renderTabs = (tabs, isSecondaryTabs) => tabs.map((tab, tabIndex) => {
  return React.createElement(UitkTab, {
    targetURI: getTargetUri(tab.action.target === UiLinkTarget.EXTERNAL, tab.action.resource.value),
    className: isSecondaryTabs && tabIndex === 0 ? 'secondary-header-tabs' : '',
    name: tab.text,
    id: tab.action.resource.value,
    key: tab.action.resource.value
  });
});
const getTargetUri = (isExternal, path) => isExternal ? path : getPathName(path);
const HeaderNavbar = ({
  primaryNavigation,
  secondaryNavigation
}) => {
  const primaryNavigationLinks = filterHeaderLinks(primaryNavigation);
  const secondaryNavigationLinks = filterHeaderLinks(secondaryNavigation);
  const headerNavigationLinks = [...primaryNavigationLinks, ...secondaryNavigationLinks];
  const {
    isHubPostfix
  } = useNavigationParams();
  const {
    push
  } = useHistory();
  const {
    currentTabId
  } = useTabContext();
  const initialTabIndex = getActiveTabIndex(currentTabId, headerNavigationLinks, isHubPostfix);
  const [selectedTabIndex, setSelectedTabIndex] = useState(initialTabIndex);
  const onTabSelectHandler = tabIndex => {
    setSelectedTabIndex(tabIndex);
    const activeTab = headerNavigationLinks[tabIndex];
    if (activeTab.action.target === UiLinkTarget.EXTERNAL) {
      return window.open(activeTab.action.resource.value);
    }
    push(getPathName(activeTab.action.resource.value));
  };
  useEffect(() => {
    const activeTabIndex = getActiveTabIndex(currentTabId, headerNavigationLinks, isHubPostfix);
    setSelectedTabIndex(activeTabIndex);
  }, [currentTabId]);
  return React.createElement("div", {
    className: "navbar-tabs"
  }, React.createElement(UitkTabs, {
    selectedTab: selectedTabIndex,
    onTabSelect: onTabSelectHandler,
    tabsType: UitkTabsType.NATURAL
  }, renderTabs(primaryNavigationLinks), renderTabs(secondaryNavigationLinks, true)));
};
export default HeaderNavbar;