import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import useAnalytics from '../../hooks/analytics/useAnalytics';
import { LunrSearchAdapter } from '../../components/search/lunr-search-adapter';
import { useHistory } from 'react-router';
import useNavigationParams from 'hooks/useNavigationParams';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import SearchBoxDesktop from 'components/page-header/desktop/search-box-desktop';
import SearchTriggerMobile from 'components/page-header/mobile/search-trigger-mobile';
export const TypeaheadSearchField = props => {
  const {
    onClick,
    onDismiss,
    searchInputProps
  } = props;
  const {
    icon,
    placeholder,
    indexFileUri
  } = searchInputProps;
  const searchAdapter = new LunrSearchAdapter(indexFileUri.value, {
    maxResults: 10
  });
  const analytics = useAnalytics();
  const {
    hubId,
    isHubPostfix
  } = useNavigationParams();
  const {
    push
  } = useHistory();
  const trackSearchInput = debounce(value => {
    if (value) {
      analytics.trackSearchInput({
        searchInput: value
      });
    }
  }, 5000);
  const handleInputChange = event => {
    trackSearchInput(event.target.value);
  };
  const handleSearch = selection => {
    const {
      term: selectedDocumentPath
    } = selection;
    const isSelectedFromResultsList = searchAdapter.getResults().some(result => result.value === selectedDocumentPath);
    if (isSelectedFromResultsList) {
      const documentId = isHubPostfix ? `/${hubId}${selection.term}` : selection.term;
      push(documentId);
    }
  };
  return React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(SearchTriggerMobile, {
    icon: icon,
    searchHandler: handleSearch,
    onInputChange: handleInputChange,
    searchAdapter: searchAdapter,
    label: placeholder,
    typeaheadPlaceholder: placeholder,
    typeaheadEmptyResultPlaceholder: placeholder
  })), React.createElement(ViewLarge, null, React.createElement(SearchBoxDesktop, {
    icon: icon,
    searchHandler: handleSearch,
    onInputChange: handleInputChange,
    searchAdapter: searchAdapter,
    onClick: onClick,
    onDismiss: onDismiss,
    label: placeholder,
    typeaheadPlaceholder: placeholder,
    typeaheadEmptyResultPlaceholder: placeholder
  })));
};
export const Search = ({
  searchInputProps
}) => {
  const searchRef = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const {
    hubId
  } = useNavigationParams();
  useEffect(() => {
    setHasMounted(true);
  }, []);
  // render this on client-side only
  if (!hasMounted) {
    return null;
  }
  return React.createElement("div", {
    ref: searchRef,
    "data-stid": "search",
    className: "search"
  }, React.createElement(TypeaheadSearchField, {
    searchInputProps: searchInputProps,
    hubId: hubId,
    onClick: onClickHandler,
    onDismiss: () => {
      if (searchRef.current) searchRef.current.classList.remove('active');
    }
  }));
  function onClickHandler() {
    if (searchRef.current) searchRef.current.classList.add('active');
  }
};