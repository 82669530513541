import React, { useState } from 'react';
import { TypeaheadRenderType, UitkTypeahead } from 'uitk-react-typeahead';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkCard } from 'uitk-react-cards';
import { UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
const SearchBoxDesktop = props => {
  const {
    searchAdapter,
    searchHandler,
    icon,
    typeaheadPlaceholder,
    label,
    typeaheadEmptyResultPlaceholder,
    onInputChange,
    onClick,
    onDismiss
  } = props;
  const onDismissHandler = () => {
    setIsOpen(false);
    onDismiss();
  };
  const [isOpen, setIsOpen] = useState(false);
  return React.createElement(UitkTypeahead, {
    id: icon.id,
    name: icon.title,
    icon: icon.token,
    label: label,
    onDismiss: onDismissHandler,
    onUpdate: searchHandler,
    onInputChange: onInputChange,
    customTrigger: {
      showTypeahead: isOpen
    },
    renderType: TypeaheadRenderType.ADAPTIVE,
    typeaheadEmptyResultPlaceholder: typeaheadEmptyResultPlaceholder,
    typeaheadPlaceholder: typeaheadPlaceholder,
    adapter: searchAdapter || {} || {},
    allowExactMatch: true,
    hasClearButton: true
  }, React.createElement("div", null, React.createElement("button", {
    onClick: () => {
      onClick();
      setIsOpen(true);
    },
    style: {
      width: '100%',
      backgroundColor: 'unset'
    }
  }, React.createElement(UitkCard, {
    backgroundTheme: "secondary"
  }, React.createElement(UitkSpacing, {
    padding: {
      inline: 'three',
      block: 'one'
    }
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "two"
  }, React.createElement(UitkText, null, React.createElement(UitkIcon, {
    name: icon.token,
    size: UitkIconSize.MEDIUM
  })), React.createElement(UitkText, null, typeaheadPlaceholder))))))));
};
export default SearchBoxDesktop;