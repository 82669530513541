import React from 'react';
export const BatmanIcon = () => React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "25",
  viewBox: "0 0 192.756 192.756"
}, React.createElement("g", {
  fill: "#0c0e1c",
  fillRule: "evenodd",
  clipRule: "evenodd"
}, React.createElement("path", {
  fill: "",
  d: "M0 0h192.756v192.756H0V0z"
}), React.createElement("path", {
  d: "M96.378 40.847c49.29 0 89.501 24.953 89.501 55.546 0 30.563-40.211 55.517-89.501 55.517-49.289 0-89.5-24.953-89.5-55.517 0-30.593 40.211-55.546 89.5-55.546z"
}), React.createElement("path", {
  d: "M96.378 45.656c46.653 0 84.706 22.795 84.706 50.738 0 27.911-38.053 50.706-84.706 50.706-46.638 0-84.691-22.795-84.691-50.706 0-27.943 38.053-50.738 84.691-50.738z",
  fill: "#fff22d"
}), React.createElement("path", {
  d: "M96.84 141.998c-4.947-23.457-20.359-32.211-25.862-13.887-11.822-22.963-37.961-16.135-22.041 6.289-3.005-1.295-5.872-2.682-8.538-4.191-8.646-5.318-15.259-11.314-19.774-17.586-3.237-5.07-4.994-10.541-4.994-16.229 0-19.774 21.115-36.758 50.861-43.694.446-.078.909-.154 1.372-.231-22.657 30.039 9.386 50.985 15.258 24.645l2.528-24.367 5.086 6.52H103.205l5.07-6.52 2.543 24.367c5.842 26.278 37.746 5.502 15.414-24.429 29.777 6.951 50.891 23.936 50.891 43.709 0 15.136-12.406 28.651-31.609 37.267 14.842-21.822-10.867-28.266-22.549-5.549-5.502-18.325-21.147-9.341-26.125 13.886z"
})));