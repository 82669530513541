import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import React from 'react';
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from 'uitk-react-images';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
export const LandingSection = ({
  contentFormatter
}) => {
  return React.createElement(UitkLayoutFlexItem, {
    style: {
      backgroundImage: `linear-gradient(0deg, rgba(31, 42, 55, 0.7), rgba(31, 42, 55, 0.9)),linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("https://a.travel-assets.com/documentation-hubs/docs/static-assets/images/46dd6ef068a012473bd62e84f1f94625.jpeg")`
    }
  }, React.createElement(UitkLayoutFlex, {
    id: "landing-section",
    direction: "column",
    tag: "section"
  }, React.createElement(UitkLayoutGrid, {
    justifyContent: "center",
    alignItems: "center",
    columns: {
      large: ['1fr', '1fr'],
      medium: ['1fr']
    },
    rows: ['auto'],
    className: "marketing-page-width"
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "six"
  }, React.createElement(UitkSpacing, {
    margin: {
      inlineend: 'twelve'
    }
  }, React.createElement("div", null, React.createElement(UitkText, {
    size: 800,
    theme: "inverse",
    spacing: "six"
  }, contentFormatter('heroSection.heading')), React.createElement(UitkText, {
    size: 600,
    theme: "inverse",
    spacing: "six"
  }, contentFormatter('heroSection.subHeading')))))), React.createElement(ViewSmall, null, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "six",
    justifyContent: "center"
  }, React.createElement(UitkText, {
    size: 700,
    theme: "inverse",
    spacing: "six",
    align: "center"
  }, contentFormatter('heroSection.heading')), React.createElement(UitkText, {
    size: 500,
    theme: "inverse",
    spacing: "six",
    align: "center"
  }, contentFormatter('heroSection.subHeading')))))), React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(UitkLayoutGrid, null, React.createElement(UitkFigure, {
    imageFit: "contain",
    ratio: UitkFigureAspectRatioType.R4_3,
    style: {
      minHeight: '774px',
      maxHeight: '774px'
    }
  }, React.createElement(UitkImage, {
    src: `https://a.travel-assets.com/documentation-hubs/docs/static-assets/images/hero-image.png`,
    alt: "heroSide"
  }))))))));
};
LandingSection.displayName = 'LandingSection';