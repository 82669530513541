import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkIllustrations } from 'uitk-react-illustrations';
import { UitkText } from 'uitk-react-text';
import { useHistory } from 'react-router-dom';
import { getHubBasePath } from '../../utils/url';
import { useDarkMode } from 'hooks/useDarkMode';
const HeaderLogo = ({
  logo,
  hubName
}) => {
  const {
    push,
    location
  } = useHistory();
  const isDarkMode = useDarkMode();
  const navigateToHomePage = () => {
    push(getHubBasePath(location.pathname));
  };
  return React.createElement(UitkLayoutFlex, {
    className: "logo-section",
    id: "logo-section",
    onClick: navigateToHomePage,
    alignItems: "center",
    space: "two"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkIllustrations, {
    alt: logo.altText,
    url: logo.imgSrc.url,
    className: `${!isDarkMode ? 'logo' : 'logo-inverted'}`
  })), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    weight: "medium",
    size: 400
  }, hubName)));
};
export default HeaderLogo;