import React from 'react';
import { Helmet } from 'react-helmet-async';
import useNavigationParams from '../../hooks/useNavigationParams';
export const CookieBanner = () => {
  const {
    hubId
  } = useNavigationParams();
  return React.createElement(Helmet, null, /* Cookies consent script */
  hubId !== 'mock' ? React.createElement("script", {
    src: "https://cmp.osano.com/AzqLGwSC5AMNnWKx/e9cbcad8-37d1-4f8e-afd3-c6dbc9678fa1/osano.js"
  }) : null);
};