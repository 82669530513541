import React from 'react';
import { UitkBadgeStandard, UitkBadgeSize } from 'uitk-react-badge';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { MinMax, UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
const capabilities = [{
  text: 'capabilitiesSection.cards.geography',
  badge: 'capabilitiesSection.badges.api',
  image: 'geography',
  href: '/docs/rapid/lodging/geography'
}, {
  text: 'capabilitiesSection.cards.properties',
  badge: 'capabilitiesSection.badges.api',
  image: 'properties',
  href: '/docs/rapid/lodging/booking/property-collect'
}, {
  text: 'capabilitiesSection.cards.guestReviews',
  badge: 'capabilitiesSection.badges.api',
  image: 'guestReviews',
  href: '/docs/rapid/lodging/content/guest-reviews'
}, {
  text: 'capabilitiesSection.cards.vacationRentals',
  badge: 'capabilitiesSection.badges.api',
  image: 'vacationRentals',
  href: '/docs/rapid/lodging/vacation-rentals'
}, {
  text: 'capabilitiesSection.cards.vrboLinkOff',
  badge: 'capabilitiesSection.badges.api',
  image: 'vrboLinkOff',
  href: '/docs/rapid/lodging/vrbo-link-off'
}, {
  text: 'capabilitiesSection.cards.notifications',
  badge: 'capabilitiesSection.badges.api',
  image: 'notifications',
  href: '/docs/rapid/lodging/notifications'
}, {
  text: 'capabilitiesSection.cards.holdResume',
  badge: 'capabilitiesSection.badges.api',
  image: 'holdResume',
  href: '/docs/rapid/lodging/booking/hold-resume'
}, {
  text: 'capabilitiesSection.cards.psd2Compliance',
  badge: 'capabilitiesSection.badges.api',
  image: 'psd2Compliance',
  href: '/docs/rapid/lodging/booking/psd2-regulation'
}, {
  text: 'capabilitiesSection.cards.manageBooking',
  badge: 'capabilitiesSection.badges.api',
  image: 'manageBooking',
  href: '/docs/rapid/lodging/manage-booking'
}, {
  text: 'capabilitiesSection.cards.propertyMessageCenter',
  badge: 'capabilitiesSection.badges.api',
  image: 'propertyMessageCenter',
  href: '/docs/rapid/lodging/manage-booking/property-message-center'
}, {
  text: 'capabilitiesSection.cards.signatureGenerator',
  badge: 'capabilitiesSection.badges.tool',
  image: 'signatureGenerator',
  href: '/docs/rapid/resources/tools/signature-generator'
}, {
  text: 'capabilitiesSection.cards.notificationsTester',
  badge: 'capabilitiesSection.badges.tool',
  image: 'notificationsTester',
  href: '/docs/rapid/resources/tools/notification-tester'
}];
const layoutGridColumnsProps = {
  large: [MinMax('48x', '76x'), MinMax('48x', '76x'), MinMax('48x', '76x'), MinMax('48x', '76x')],
  medium: [MinMax('30x', '72x'), MinMax('30x', '72x'), MinMax('30x', '72x')],
  small: [MinMax('min-content', '72x'), MinMax('min-content', '72x')]
};
const layoutGridRowProps = {
  large: ['48x', '48x', '48x'],
  medium: ['38x', '38x', '38x', '38x'],
  small: ['38x', '38x', '38x', '38x', '38x', '38x']
};
const CapabilitiesSection = ({
  formatContent
}) => {
  return React.createElement(UitkLayoutFlexItem, {
    className: "capabilities-section home-spacing"
  }, React.createElement("section", {
    id: "capabilities-section"
  }, React.createElement(UitkSpacing, {
    className: "marketing-page-width"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      block: 'twelve'
    }
  }, React.createElement(UitkText, {
    size: 800,
    align: "center"
  }, formatContent('capabilitiesSection.heading'))), React.createElement(UitkLayoutGrid, {
    justifyContent: "center",
    columns: layoutGridColumnsProps,
    rows: layoutGridRowProps,
    space: "three"
  }, capabilities.map(({
    text,
    badge,
    image,
    href
  }, i) => React.createElement("a", {
    href: href,
    style: {
      height: 'inherit'
    },
    key: i
  }, React.createElement(UitkLayoutGridItem, {
    key: text
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: "center",
    alignItems: "center",
    direction: "column",
    className: "image",
    style: {
      height: '100%',
      '--backgroundImage': `url(https://a.travel-assets.com/documentation-hubs/docs/static-assets/images/capabilities/${image}.jpeg)`
    }
  }, React.createElement(UitkBadgeStandard, {
    size: UitkBadgeSize.LARGE,
    text: formatContent(badge)
  }), React.createElement(UitkText, {
    className: "description",
    spacing: "three",
    align: "center"
  }, formatContent(text)))))))))));
};
export default CapabilitiesSection;