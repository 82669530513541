import React, { memo, useEffect, useState } from 'react';
import { UitkExpandoList } from 'uitk-react-expando';
import { useSidebarQuery } from '../../graphql/__generated__/types';
import { useBexApi } from '@shared-ui/bex-api-context';
import useNavigationParams from '../../hooks/useNavigationParams';
import SidebarSection from './sidebar-section/sidebar-section';
import useGraphqlErrors from '../../hooks/useGraphqlErrors';
import ProductPlaceHolder from '../product-placeholder';
import LoadingSkeleton from './loading-skeleton';
import { UitkSpacing } from 'uitk-react-spacing';
import useSideBarVisibility from '../../hooks/useSideBarVisiblity';
import useMessageBanner from '../../hooks/useMessageBanner';
import useTabContext from '../../hooks/useTabContext';
const Sidebar = () => {
  const {
    context
  } = useBexApi();
  const {
    hubId,
    documentId
  } = useNavigationParams();
  const [sidebarQueryDocumentId, setSidebarQueryDocumentId] = useState(documentId);
  const {
    setHasError
  } = useGraphqlErrors();
  const {
    setHideSideBar
  } = useSideBarVisibility();
  const {
    setMessageBanner
  } = useMessageBanner();
  const {
    data,
    error,
    loading
  } = useSidebarQuery({
    variables: {
      hubId,
      documentId: sidebarQueryDocumentId,
      context
    }
  });
  const {
    onTabIdChange,
    currentTabId
  } = useTabContext();
  useEffect(() => {
    if (error) setHasError(true);
  }, [error]);
  React.useEffect(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    if (data) {
      if (((_c = (_b = (_a = data.documentation) === null || _a === void 0 ? void 0 : _a.sidebar) === null || _b === void 0 ? void 0 : _b.topLevelNavigationItem) === null || _c === void 0 ? void 0 : _c.id) !== currentTabId) {
        onTabIdChange((_f = (_e = (_d = data.documentation) === null || _d === void 0 ? void 0 : _d.sidebar) === null || _e === void 0 ? void 0 : _e.topLevelNavigationItem) === null || _f === void 0 ? void 0 : _f.id);
      }
      if ((_h = (_g = data.documentation) === null || _g === void 0 ? void 0 : _g.sidebar) === null || _h === void 0 ? void 0 : _h.elements.length) {
        setHideSideBar(false);
      } else {
        setHideSideBar(true);
      }
      if (((_k = (_j = data.documentation) === null || _j === void 0 ? void 0 : _j.sidebar) === null || _k === void 0 ? void 0 : _k.notifications) !== null) {
        setMessageBanner({
          message: (_o = (_m = (_l = data.documentation) === null || _l === void 0 ? void 0 : _l.sidebar) === null || _m === void 0 ? void 0 : _m.notifications[0]) === null || _o === void 0 ? void 0 : _o.message,
          iconName: (_r = (_q = (_p = data.documentation) === null || _p === void 0 ? void 0 : _p.sidebar) === null || _q === void 0 ? void 0 : _q.notifications[0]) === null || _r === void 0 ? void 0 : _r.icon.token,
          messageStyle: {
            marginLeft: '1rem'
          }
        });
      }
    }
  }, [data]);
  useEffect(() => {
    var _a;
    const spaceDocumentIds = (_a = data === null || data === void 0 ? void 0 : data.documentation) === null || _a === void 0 ? void 0 : _a.sidebar.spaceDocumentIds;
    if (!(spaceDocumentIds === null || spaceDocumentIds === void 0 ? void 0 : spaceDocumentIds.includes(documentId))) {
      setSidebarQueryDocumentId(documentId);
    }
  }, [documentId]);
  if (loading || !data) {
    return React.createElement(UitkSpacing, {
      margin: {
        block: 'three'
      }
    }, React.createElement("div", null, React.createElement(LoadingSkeleton, null)));
  }
  if (!data.documentation.sidebar.elements.length) {
    return null;
  }
  const sections = data.documentation.sidebar.elements;
  const {
    title: {
      text
    }
  } = data.documentation.sidebar.heading;
  return React.createElement("div", null, React.createElement("div", null, React.createElement(ProductPlaceHolder, {
    label: text
  })), React.createElement("div", {
    className: "sidebar-content"
  }, React.createElement(UitkExpandoList, {
    "data-testid": "sidebar"
  }, React.createElement(SidebarSections, {
    sections: sections
  }))));
};
const SidebarSections = ({
  sections
}) => React.createElement("div", {
  "data-testid": "sidebar-short-title"
}, sections.map((section, index) => React.createElement(SidebarSection, {
  key: index,
  section: section,
  withBottomBorder: index !== sections.length - 1
})));
export default memo(Sidebar);