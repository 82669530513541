import React from 'react';
import PageHeader from '../../components/page-header/page-header';
import ErrorPage from '../../components/common/error-page';
import ErrorContextProvider from '../../contexts/graphql-errors-context';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import HomePageMobileLayout from '../../components/layout/home-page-mobile-layout';
import MobileSidebar from '../../components/sidebar/mobile-sidebar';
import HomePageLayout from '../../components/layout/home-page-layout';
import Sidebar from '../../components/sidebar/sidebar';
import useNavigationParams from '../../hooks/useNavigationParams';
import MarketingPageLayout from '../../components/layout/marketing-page-layout';
import DocumentView from '../../components/document-view/document-view';
import { DevhubMarketingPage } from '../../marketing-pages/devhub';
import useGraphqlErrors from '../../hooks/useGraphqlErrors';
import useSideBarVisibility from '../../hooks/useSideBarVisiblity';
import SideBarVisibilityContextProvider from '../../contexts/sidebar-visibility-context';
import MessageBannerContextProvider from 'contexts/message-banner-context';
import HeaderTitle from '../../components/header-title';
import HomeDocumentView from '../../components/document-view/home-document-view';
const HomeContent = () => React.createElement(ErrorContextProvider, null, React.createElement(MessageBannerContextProvider, null, React.createElement(SideBarVisibilityContextProvider, null, React.createElement(HomePage, null))));
const HomePage = () => {
  const {
    hasError
  } = useGraphqlErrors();
  const {
    hubId,
    tabId
  } = useNavigationParams();
  const {
    hideSideBar
  } = useSideBarVisibility();
  if (hasError) {
    return React.createElement(ErrorPage, null);
  }
  if (!tabId) {
    return hubId.startsWith('docs') || hubId.startsWith('mock') ? React.createElement(React.Fragment, null, React.createElement(MarketingPageLayout, {
      header: React.createElement(PageHeader, null),
      body: React.createElement(DevhubMarketingPage, {
        hubId: hubId
      })
    }), React.createElement(HeaderTitle, null)) : React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(HomePageMobileLayout, {
      header: React.createElement(PageHeader, null),
      sidebar: null,
      document: React.createElement(HomeDocumentView, null)
    })), React.createElement(ViewLarge, null, React.createElement(HomePageLayout, {
      hideSideBar: true,
      header: React.createElement(PageHeader, null),
      sidebar: null,
      document: React.createElement(HomeDocumentView, null)
    })));
  }
  return React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(HomePageMobileLayout, {
    header: React.createElement(PageHeader, null),
    sidebar: React.createElement(MobileSidebar, {
      hideSideBar: hideSideBar
    }),
    document: React.createElement(DocumentView, null)
  })), React.createElement(ViewLarge, null, React.createElement(HomePageLayout, {
    hideSideBar: hideSideBar,
    header: React.createElement(PageHeader, null),
    sidebar: React.createElement(Sidebar, null),
    document: React.createElement(DocumentView, null)
  })));
};
export default HomeContent;