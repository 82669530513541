import React, { useState } from 'react';
import { UitkPrimaryButton, UitkSecondaryButton } from 'uitk-react-button';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { UitkMenuIconTrigger, UitkMenuList } from 'uitk-react-menu';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { getPathName } from '../../utils/common';
import useAnalytics from '../../hooks/analytics/useAnalytics';
import { UitkMenu, UitkMenuContainer, UitkMenuTrigger } from 'uitk-react-menu';
const SignInButtonMenu = ({
  auth,
  onButtonClick
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonsAsMenuItems = auth.buttons.map(button => {
    var _a;
    return {
      label: button.primary,
      className: 'uitk-list-item',
      onClick: () => onButtonClick(button.action),
      icon: {
        name: (_a = button.icon) === null || _a === void 0 ? void 0 : _a.token
      }
    };
  });
  return React.createElement(UitkMenu, {
    isOpen: isOpen,
    onTriggerClick: () => setIsOpen(!isOpen)
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkMenuIconTrigger, {
    className: "custom-trigger-class",
    icon: "account_circle",
    iconSize: UitkIconSize.MEDIUM
  })), React.createElement(UitkMenuContainer, {
    autoPosition: true
  }, React.createElement(UitkMenuList, {
    items: buttonsAsMenuItems
  })));
};
const SignInButtonFlat = ({
  auth,
  onButtonClick
}) => {
  const authButtons = auth.buttons.map(button => {
    var _a;
    const authLinkAction = button.action;
    const Button = button.__typename === 'UISecondaryButton' ? UitkSecondaryButton : UitkPrimaryButton;
    return React.createElement(Button, {
      key: button.primary,
      "data-testid": button.primary,
      onClick: () => onButtonClick(authLinkAction)
    }, button.primary, button.icon && React.createElement(UitkIcon, {
      name: (_a = button.icon) === null || _a === void 0 ? void 0 : _a.token
    }));
  });
  return React.createElement(React.Fragment, null, authButtons);
};
const SignInButton = ({
  auth
}) => {
  const {
    trackSignInSelected
  } = useAnalytics();
  const buttonOnClickHandler = action => {
    trackSignInSelected();
    const target = action.target === 'EXTERNAL' ? '_blank' : '_self';
    const targetPathName = getPathName(action.resource.value);
    const targetURI = targetPathName === '/sign-in' ? `${targetPathName}?destination=${window.location.pathname}` : action.resource.value;
    return window.open(targetURI, target);
  };
  return React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(SignInButtonMenu, {
    auth: auth,
    onButtonClick: buttonOnClickHandler
  })), React.createElement(ViewLarge, null, React.createElement(SignInButtonFlat, {
    auth: auth,
    onButtonClick: buttonOnClickHandler
  })));
};
export default SignInButton;