import React from 'react';
import { BaseDialog } from './base-dialog';
import useLocale from '../../hooks/useLocale';
import { clearSession } from '../../views/sign-out-page/sign-out-functions';
export const NoAccountDialog = () => {
  const {
    formatContent
  } = useLocale();
  const signout = () => {
    return fetch(`/eg-auth-svcs/v1/account/signout`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  };
  const openDialogHandler = () => {
    signout().finally(async () => {
      await clearSession(); // clear cache after expire token request
    });
  };
  return React.createElement(React.Fragment, null, React.createElement(BaseDialog, {
    dialogId: "no-account",
    dialogLabel: formatContent('devhub.dialog.no-account.label'),
    headerText: formatContent('devhub.dialog.no-account.header'),
    bodyText: formatContent('devhub.dialog.no-account.body'),
    buttonText: formatContent('devhub.dialog.no-account.button'),
    openDialogHandler: openDialogHandler
  }));
};