import React, { useState } from 'react';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkTextInput } from 'uitk-react-input';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';
import { Transition } from 'react-transition-group';
import useAnalytics from '../../hooks/analytics/useAnalytics';
import { useBexApi } from '@shared-ui/bex-api-context';
import useNavigationParams from 'hooks/useNavigationParams';
import { useFeedbackQuery } from '../../graphql/__generated__/types';
export const getFeedbackType = feedback => feedback.toString() === 'true' ? 'positive' : 'negative';
const TRANSITION_DURATION = 300;
const defaultTransitionStyle = {
  maxHeight: 0,
  transition: [`opacity ${TRANSITION_DURATION}ms ease-in 0s`, `background ${TRANSITION_DURATION}ms ease-in 0s`, `max-height ${TRANSITION_DURATION}ms ease-in 0s`].join(', ')
};
const ContentFeedback = () => {
  const {
    context
  } = useBexApi();
  const {
    hubId
  } = useNavigationParams();
  const {
    data,
    loading,
    error
  } = useFeedbackQuery({
    variables: {
      hubId,
      context
    }
  });
  const initialFormData = {
    wasHelpful: null,
    suggestions: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [step, setStep] = useState(0);
  const analytics = useAnalytics();
  const handleTextFeedbackChange = e => {
    setFormData({
      ...formData,
      suggestions: e.currentTarget.value
    });
  };
  if (loading || error) return null;
  const {
    feedback
  } = data.documentation;
  const handleFeedbackSubmission = () => {
    setStep(2);
    const type = getFeedbackType(formData.wasHelpful);
    analytics.trackFeedbackReason({
      type,
      reason: formData.suggestions
    });
    setFormData(initialFormData);
  };
  const handleFeedbackButtonClick = event => {
    setStep(1);
    const type = getFeedbackType(event.currentTarget.value);
    analytics.trackFeedbackIntent({
      type
    });
    setFormData({
      ...formData,
      wasHelpful: event.currentTarget.value === 'true'
    });
  };
  const transitionStyle = {
    entering: {
      maxHeight: 0,
      opacity: 0
    },
    entered: {
      maxHeight: 'initial',
      opacity: 1
    },
    exiting: {
      maxHeight: 'initial',
      opacity: 0
    },
    exited: {
      maxHeight: 0,
      opacity: 0
    }
  };
  return React.createElement(React.Fragment, null, React.createElement(Transition, {
    in: step === 0,
    timeout: TRANSITION_DURATION
  }, state => React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "three",
    style: {
      ...defaultTransitionStyle,
      ...transitionStyle[state]
    }
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    size: 200,
    weight: "medium"
  }, feedback.question.text)), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    space: "three"
  }, React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.SMALL,
    type: "button",
    onClick: handleFeedbackButtonClick,
    value: "true"
  }, React.createElement(UitkIcon, {
    name: feedback.thumpsUp.icon.token,
    size: UitkIconSize[feedback.thumpsUp.icon.size]
  }), feedback.thumpsUp.primary), React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.SMALL,
    type: "button",
    onClick: handleFeedbackButtonClick,
    value: "false"
  }, React.createElement(UitkIcon, {
    name: feedback.thumpsDown.icon.token,
    size: UitkIconSize[feedback.thumpsDown.icon.size]
  }), feedback.thumpsDown.primary))))), React.createElement(Transition, {
    in: step === 1,
    timeout: TRANSITION_DURATION
  }, state => React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "four",
    style: {
      ...defaultTransitionStyle,
      ...transitionStyle[state]
    }
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    size: 200,
    weight: "medium"
  }, feedback.suggestionForm.input.label)), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkTextInput, {
    label: feedback.suggestionForm.input.placeholder,
    value: formData.suggestions,
    onChange: handleTextFeedbackChange,
    id: "suggestions"
  })), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "end"
  }, React.createElement(UitkPrimaryButton, {
    disabled: !formData.suggestions,
    type: "submit",
    onClick: handleFeedbackSubmission
  }, feedback.suggestionForm.submit.primary)))), React.createElement(Transition, {
    in: step === 2,
    timeout: TRANSITION_DURATION
  }, state => React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "four",
    style: {
      ...defaultTransitionStyle,
      ...transitionStyle[state]
    }
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    size: 200,
    weight: "medium"
  }, feedback.suggestionForm.thankYouMessage.text)))));
};
export default ContentFeedback;