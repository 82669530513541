import React, { useEffect } from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { BrandSection } from './sections/BrandSection';
import CapabilitiesSection from './sections/CapabilitiesSection';
import { LandingSection } from './sections/LandingSection';
import LodgingSection from './sections/lodging-section/lodging-section';
import { RelationsSection } from './sections/RelationsSection';
import SolutionsCards from './sections/SolutionCards';
import useLocale from '../../hooks/useLocale';
import Footer from '../../components/footer';
import useAnalytics from '../../hooks/analytics/useAnalytics';
import { useBexApi } from '@shared-ui/bex-api-context';
export const DevhubMarketingPage = ({
  hubId
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const analytics = useAnalytics();
  const {
    context
  } = useBexApi();
  useEffect(() => {
    analytics.trackPageView();
  }, [hubId, context.locale]);
  const {
    formatContent
  } = useLocale();
  const contentFormatter = label => formatContent(`devhub.home.${label}`);
  return React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(LandingSection, {
    contentFormatter: contentFormatter
  }), React.createElement(BrandSection, {
    brandName: contentFormatter('brandsSection.EPS'),
    description: contentFormatter('brandsSection.EPS.description'),
    link: {
      label: contentFormatter('brandsSection.EPS.linkText'),
      href: `/${hubId}/rapid/setup/getting-started`
    },
    image: {
      src: `https://a.travel-assets.com/documentation-hubs/docs/static-assets/images/1_eps.png`,
      alt: 'brandImageEPS'
    },
    cards: [{
      title: contentFormatter('brandsSection.EPS.cards.booking'),
      description: contentFormatter('brandsSection.EPS.cards.booking.description'),
      link: {
        label: contentFormatter('brandsSection.EPS.cards.booking.link'),
        href: `/${hubId}/rapid/lodging/booking`
      }
    }, {
      title: contentFormatter('brandsSection.EPS.cards.shopping'),
      description: contentFormatter('brandsSection.EPS.cards.shopping.description'),
      link: {
        label: contentFormatter('brandsSection.EPS.cards.shopping.link'),
        href: `/${hubId}/rapid/lodging/shopping`
      }
    }, {
      title: contentFormatter('brandsSection.EPS.cards.content'),
      description: contentFormatter('brandsSection.EPS.cards.content.description'),
      link: {
        label: contentFormatter('brandsSection.EPS.cards.content.link'),
        href: `/${hubId}/rapid/lodging/content`
      }
    }],
    title: contentFormatter('brandsSection.EPS.title')
  }), React.createElement(UitkSpacingHr, null), React.createElement(LodgingSection, {
    formatContent: contentFormatter
  }), React.createElement(RelationsSection, {
    formatContent: contentFormatter
  }), React.createElement(CapabilitiesSection, {
    formatContent: contentFormatter
  }), React.createElement(SolutionsCards, {
    title: contentFormatter('solutionsSection.heading'),
    cards: [{
      heading: contentFormatter('solutionsSection.cards.eps.heading'),
      subHeading: contentFormatter('solutionsSection.cards.eps.sub-heading'),
      buttons: [{
        label: contentFormatter('solutionsSection.cards.eps.gettingStartedButton'),
        href: `/${hubId}/rapid/setup/getting-started`
      }, {
        label: contentFormatter('solutionsSection.cards.changelogButton'),
        href: `/${hubId}/rapid/setup/changelog`
      }]
    }, {
      heading: contentFormatter('solutionsSection.cards.lodging.heading'),
      subHeading: contentFormatter('solutionsSection.cards.lodging.sub-heading'),
      buttons: [{
        label: contentFormatter('solutionsSection.cards.lodging.documentation'),
        href: 'https://developers.expediagroup.com/supply/lodging/docs/'
      }, {
        label: contentFormatter('solutionsSection.cards.lodging.whatsNew'),
        href: 'https://developers.expediagroup.com/supply/lodging/updates'
      }]
    }]
  }), React.createElement(UitkLayoutFlexItem, null, React.createElement(Footer, null)));
};
DevhubMarketingPage.displayName = 'MarketingPage';