import React from 'react';
const MarketingPageLayout = ({
  header,
  body
}) => React.createElement("div", {
  id: "marketing-page-layout"
}, React.createElement("header", {
  id: "page-header"
}, header), React.createElement("div", {
  className: "marketing-page-container"
}, React.createElement("main", {
  id: "page-body"
}, React.createElement("section", {
  id: "document"
}, body))));
export default MarketingPageLayout;