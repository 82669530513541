import { getPathName, removeHubNameFromPath } from './common';
export const filterHeaderLinks = headerNavs => {
  return headerNavs.filter(nav => nav.__typename === 'EGDSStandardLink');
};
export const getActiveTabIndex = (tabId, tabs, isHubPostfix) => {
  if (tabId === '') {
    return 0;
  }
  const activeTabIndex = tabs.findIndex(tab => {
    const tabPath = isHubPostfix ? removeHubNameFromPath(getPathName(tab.action.resource.value)) : getPathName(tab.action.resource.value).replace('/', '');
    return tabPath === tabId;
  });
  return activeTabIndex;
};