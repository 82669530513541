import React from 'react';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';
import { UitkSpacing, UitkSpacingHr } from 'uitk-react-spacing';
import { UitkLink } from 'uitk-react-link';
import useAnalytics from '../../../hooks/analytics/useAnalytics';
export const AuthenticatedUserSubMenuButton = ({
  button,
  underlined
}) => {
  var _a;
  const {
    trackSignOutSubmitted
  } = useAnalytics();
  const action = button.action;
  if (action.__typename !== 'UILinkAction') {
    return null;
  }
  return React.createElement("div", null, React.createElement(UitkSpacing, {
    padding: {
      inlinestart: 'six',
      blockstart: 'six',
      blockend: 'six'
    }
  }, React.createElement(UitkLink, null, React.createElement("a", {
    href: action.resource.value,
    target: action.target === 'EXTERNAL' ? '_blank' : '_self',
    onClick: () => {
      if (action.target !== 'EXTERNAL') trackSignOutSubmitted();
    }
  }, React.createElement(UitkLayoutFlex, {
    space: "two"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    theme: "default"
  }, button.primary)), ((_a = button.icon) === null || _a === void 0 ? void 0 : _a.token) && React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkIcon, {
    name: button.icon.token,
    size: UitkIconSize.SMALL,
    theme: "default"
  })))))), underlined && React.createElement(UitkSpacingHr, {
    blockMargin: "unset"
  }));
};