import React, { createContext, useState } from 'react';
export const MessageBannerContext = createContext(null);
const MessageBannerContextProvider = ({
  children
}) => {
  const [messageBanner, setMessageBanner] = useState(null);
  return React.createElement(MessageBannerContext.Provider, {
    value: {
      messageBanner,
      setMessageBanner
    }
  }, children);
};
export default MessageBannerContextProvider;