import React from 'react';
import SignInButton from '../sign-in-button';
import { AuthenticatedUserMenuMobile } from './authenticated-user-menu-mobile';
export const AuthenticationMobile = ({
  authentication
}) => {
  if (authentication.menu) {
    return React.createElement(AuthenticatedUserMenuMobile, {
      menu: authentication.menu
    });
  }
  return React.createElement(SignInButton, {
    auth: authentication
  });
};